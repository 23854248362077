import React, { useContext, useEffect, useRef, useState } from 'react';
import { indoor } from 'azure-maps-indoor';
import { layer, Map as AzureMap, source } from 'azure-maps-control'; // Referencing 'atlas' import directly seems to sometimes not work (ie. atlas.source.DateSource)
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import {
    changeMap,
    createAzureMap,
    createSeatStatusColorShapes,
    FacilitiesAzureIndoorMapBaseProps,
    getFacilitySeatDict,
    getIndoorManagerOptions,
    getSeatFeatureClicked,
    defaultMapContainerId,
    AzureMapThemeColor,
    addPolygonLayer,
    addZoomControl,
    addLineLayer,
    createPopup,
    getFeatureCenterPosition,
} from 'components/facilities/facilities-azure-indoor-map/facilities-azure-indoor-map-common';
import {
    IFacilityRecord,
    ISeatRecord,
    ReservationState,
    SeatQualifiers,
    SeatStatuses,
} from 'clients/facilities-client';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import CancelReservationModalActionButton from 'components/facilities/facilities-reservations/modals/facilities-reservation-cancel-modal-action-button';
import ChangeReservationModal from 'components/facilities/facilities-reservations/modals/facilities-reservation-change-modal';
import { mergeStyleSets } from '@fluentui/react';
import { azureMapLegendClass } from 'components/facilities/facilities-legend';
import FacilitiesReservationModalV2 from 'components/facilities/facilities-reservations/modals/facilities-reservation-modal-v2';
import { ITimeAvailability } from 'components/facilities/facilities-page';
import {
    ISeatSlot,
    seatSlotsForSeats,
} from 'components/facilities/common/facilities-timeslot-utils';
import {
    ICancelationInfo,
    IMakeReservationInfo,
    IRescheduleInfo,
    IReservationInstructions,
    IReservationPromiseInstruction,
    createCancelPromises,
    createMakePromises,
    createReschedulePromises,
} from 'components/facilities/facilities-reservations/modals/facilities-reservation-instruction-utils';

export interface FacilitiesAzureIndoorMapProps extends FacilitiesAzureIndoorMapBaseProps {
    updateFacilitySeatStatuses?: (updates: IReservationPromiseInstruction[]) => void;
    timeAvailability?: ITimeAvailability;
    visible?: boolean;
    mapContainerId?: string;
    setPostLoadedClass?: boolean;
    showIndoorMapView?: boolean;
}

interface IEventObject {
    facilitySeats?: ISeatRecord[];
    seatSlots?: ISeatSlot[];
    isViewOnly?: boolean;
}

export default function FacilitiesAzureIndoorMap(
    props: FacilitiesAzureIndoorMapProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [map, setMap] = useState<AzureMap>();
    const [indoorManager, setIndoorManager] = useState<indoor.IndoorManager>();
    const [dataSource, setDataSource] = useState<source.DataSource>();

    const [selectedSeat, setSelectedSeat] = useState<ISeatRecord>();
    const [isReservationDialogOpen, setReservationDialogOpen] = useState<boolean>(false);
    const [isCancelReservationDialogOpen, setCancelReservationDialogOpen] = useState<boolean>(
        false,
    );
    const [isChangeReservationDialogOpen, setChangeReservationDialogOpen] = useState<boolean>(
        false,
    );

    const [seatSlots, setSeatSlots] = useState<ISeatSlot[]>([]);
    const [eventObj] = useState<IEventObject>({
        facilitySeats: [],
        seatSlots: [],
        isViewOnly: false,
    });
    const eventObjRef = useRef(eventObj);
    const divRef = useRef<HTMLDivElement>(null);

    async function resolveCalendarResponses(
        timeAvailability: ITimeAvailability,
        facilitySeats: ISeatRecord[],
    ): Promise<void> {
        if (timeAvailability.calendarResponses) {
            const results = await timeAvailability.calendarResponses;
            const varSeatSlots = seatSlotsForSeats(
                timeAvailability.startTime,
                timeAvailability.endTime,
                results,
                facilitySeats,
            );
            setSeatSlots(varSeatSlots);
        }
    }

    useEffect(() => {
        if (props.timeAvailability && props.facilitySeats) {
            setSelectedSeat(undefined);
            resolveCalendarResponses(props.timeAvailability, props.facilitySeats);
        }
    }, [props.timeAvailability, props.facilitySeats]);

    useEffect(() => {
        if (props.facility) {
            generateMap();
        }
    }, [props.facility]);

    useEffect(() => {
        if (map && dataSource && props.facilitySeats && props.featuresLoaded && seatSlots) {
            createSeatStatusColorShapes(map, dataSource, props.facilitySeats, seatSlots);
        }
    }, [map, dataSource, seatSlots, props.facilitySeats, props.featuresLoaded]);

    // NOTE: see if this fixes the facilities kiosk client on desktop not initially showing the map
    useEffect(() => {
        if (authContext.isKioskRenderMode() && props.featuresLoaded) {
            divRef.current?.focus();
            divRef.current?.click();
        }
    }, [props.featuresLoaded]);

    useEffect(() => {
        if (map && props.onFeaturesLoaded) {
            const featureCount = { prev: -1, curr: -1 };
            const interval = setInterval(() => {
                if (hasFeaturesLoaded()) {
                    featureCount.curr = map.layers.getRenderedShapes().length;
                    if (featureCount.prev > -1 && featureCount.prev === featureCount.curr) {
                        // all features have been loaded from indoor map tileset
                        props.onFeaturesLoaded && props.onFeaturesLoaded();
                        clearInterval(interval);
                    }

                    featureCount.prev = featureCount.curr;
                }
            }, 500);
            return () => clearInterval(interval);
        }
    }, [map]);

    useEffect(() => {
        eventObj.facilitySeats = props.facilitySeats;
        eventObj.seatSlots = seatSlots;
        eventObj.isViewOnly = props.isViewOnly;
    }, [props.facilitySeats, seatSlots, props.isViewOnly]); // put all fields used by the map event listeners here

    // clean up resources when component unmounts from the DOM
    useEffect(() => {
        return () => cleanUp();
    }, []);

    function hasFeaturesLoaded(): boolean {
        return !!map && map.layers.getRenderedShapes().length > 0;
    }

    async function generateMap(): Promise<void> {
        if (!map) {
            await createMap();
        } else {
            changeMap(map, indoorManager, props.facility, props.theme);
        }
    }

    async function createMap(): Promise<void> {
        if (authContext.isKioskRenderMode()) {
            clearOldMapDivs();
        }

        const newMap = await createAzureMap(
            authContext,
            userContext,
            props.facility,
            props.mapContainerId,
        );

        // Wait until the map resources are ready
        newMap.events.add('ready', () => {
            addIndoorManager(newMap);
            addZoomControl(newMap);

            // Change the cursor of the mouse when it is over the map to be a pointer
            newMap.getCanvasContainer().style.cursor = 'pointer';

            // Create a data source and add it to the map.
            const newDataSource = new source.DataSource();
            newMap.sources.add(newDataSource);

            setDataSource(newDataSource);

            const polygonLayer = addPolygonLayer(newMap, newDataSource);

            addLineLayer(newMap, newDataSource);

            addEvents(newMap, polygonLayer);
        });

        setMap(newMap);
    }

    function clearOldMapDivs(): void {
        //delete any previous map divs possibly on the page to prevent ghost map layers from being displayed in the background

        const previousMapDivs = divRef.current?.children;

        if (previousMapDivs) {
            for (const div of previousMapDivs) {
                if (!div.className.startsWith(azureMapLegendClass)) {
                    div.innerHTML = '';
                }
            }
        }
    }

    function clearMapIfKiosk(): void {
        if (authContext.isKioskRenderMode()) {
            setMap(undefined);
        }
    }

    function addIndoorManager(azureMap: AzureMap): void {
        const newIndoorManager = new indoor.IndoorManager(
            azureMap as indoor.IMap,
            getIndoorManagerOptions(props.facility, props.theme),
        );

        setIndoorManager(newIndoorManager);
    }

    useEffect(() => {
        if (selectedSeat && seatSlots && props.facility) {
            const selectedSeatSlot = seatSlots.find((x) => x.seatId === selectedSeat.id)!;
            const existingAssignedSeats = seatSlots.filter(
                (x) => x.status === SeatStatuses.MySeat.value,
            );
            const hasExistingSeatBeenSelected =
                existingAssignedSeats.find((x) => x.seatId === selectedSeat.id) !== undefined;

            const existingReservations = existingAssignedSeats.flatMap((x) => x.timeslots);
            const existingConfirmedReservations = existingReservations.filter(
                (y) => y.seatStatus.reservationState === ReservationState.Confirmed,
            );

            if (existingReservations?.length > 0) {
                if (
                    hasExistingSeatBeenSelected &&
                    existingReservations.length === existingConfirmedReservations.length
                ) {
                    // Cancel seat reservation

                    const cancelInstructions = existingConfirmedReservations.map((y) => {
                        return {
                            timeslot: y,
                            facility: props.facility,
                        } as ICancelationInfo;
                    });

                    handleMakingReservation(
                        'cancel',
                        props.facility,
                        undefined,
                        undefined,
                        cancelInstructions,
                    );
                } else if (existingConfirmedReservations?.length > 0) {
                    const rescheduleInstructions = existingConfirmedReservations.map((y) => {
                        return {
                            currentTimeSlot: y,
                            changeToSeatId: selectedSeat.id,
                            facility: props.facility,
                        } as IRescheduleInfo;
                    });

                    const makeInstructions = selectedSeatSlot?.timeslots
                        .filter((x) => x.seatStatus.reservationState === undefined) // Make sure the seat is free
                        .filter(
                            (x) =>
                                rescheduleInstructions.findIndex(
                                    (y) =>
                                        y.currentTimeSlot.timeslotItem
                                            .startDateTimeUTCMilliseconds ===
                                        x.timeslotItem.startDateTimeUTCMilliseconds,
                                ) === -1,
                        ) // Make sure this is not a timeslot we're rescheduling
                        .map((x) => {
                            return {
                                timeslot: x.timeslotItem,
                                seatId: x.seatStatus.seatId,
                                facility: props.facility!,
                            } as IMakeReservationInfo;
                        });

                    handleMakingReservation(
                        'reschedule',
                        props.facility,
                        makeInstructions,
                        rescheduleInstructions,
                        undefined,
                    );
                    // if existingAssignedSeats are found assigned to the user, need to remove them and consolidate to new seat
                }
            } else if (selectedSeatSlot) {
                const makeInstructions = selectedSeatSlot?.timeslots.map((x) => {
                    return {
                        timeslot: x.timeslotItem,
                        seatId: x.seatStatus.seatId,
                        facility: props.facility!,
                    } as IMakeReservationInfo;
                });

                handleMakingReservation(
                    'schedule',
                    props.facility,
                    makeInstructions,
                    undefined,
                    undefined,
                );
            }
        }
    }, [selectedSeat, seatSlots]);

    function addEvents(azureMap: AzureMap, polygonLayer: layer.PolygonLayer): void {
        // Left mouse click
        azureMap.events.add('click', (e) => {
            if (eventObjRef.current.isViewOnly) {
                return;
            }

            if (e && e.shapes) {
                // Get the seat feature clicked
                const seatDict = getFacilitySeatDict(eventObjRef.current.facilitySeats);
                const seatFeature = getSeatFeatureClicked(e.shapes, seatDict);
                if (seatFeature) {
                    const seat = seatDict[seatFeature.properties?.name.toLowerCase()];
                    if (!seat) {
                        return;
                    }
                    setSelectedSeat(seat);
                }
            }
        });

        if (!authContext.isKioskRenderMode()) {
            const qualifiersPopup = createPopup();

            azureMap.events.add('mouseover', polygonLayer, (e) => {
                if (e.shapes && e.shapes.length > 0) {
                    const shape: any = e.shapes[0];
                    if (shape.data.type === 'Feature' && eventObjRef.current.facilitySeats) {
                        const seat = eventObjRef.current.facilitySeats.find(
                            (s) => s.id === shape.data.id,
                        );

                        if (seat && seat.qualifiers.some((sq) => sq !== '')) {
                            const formattedQualifiers = formatQualifiersForDisplay(
                                seat.qualifiers.filter((sq) => sq !== ''),
                            );

                            qualifiersPopup.setOptions({
                                content: `<div>${formattedQualifiers.join(', ')}</div>`,
                                position: getFeatureCenterPosition(shape.data),
                            });
                            qualifiersPopup.open(azureMap);
                        }
                    }
                }
            });

            azureMap.events.add('mouseout', polygonLayer, () => {
                qualifiersPopup.close();
            });
        }
        /*
        // Right mouse click
        azureMap.events.add('contextmenu', (e) => {
            if (eventObjRef.current.isViewOnly) return;

            if (e && e.shapes) {
            }
        });

        // Mouse hover
        azureMap.events.add('mouseover', (e) => {
            if (eventObjRef.current.isViewOnly) return;

            if (e && e.shapes) {
            }
        });

        // Touch screen click
        azureMap.events.add('touchend', (e) => {
            if (eventObjRef.current.isViewOnly) return;

            if (e && e.shapes) {
                // NOTE: This event only provides shape objects clicked so it cannot be used until we
                // create shapes that map to our seat records or seat features (depending on the ids given to shapes)
            }
        });
        */
    }

    function formatQualifiersForDisplay(qualifiers: string[]): string[] {
        const formattedQualifiers: string[] = [];

        if (qualifiers) {
            qualifiers.forEach((q) => {
                switch (q) {
                    case SeatQualifiers.CustomerFacingAVC.key:
                        formattedQualifiers.push(SeatQualifiers.CustomerFacingAVC.value);
                        break;
                    case SeatQualifiers.CustomerFacingMPO.key:
                        formattedQualifiers.push(SeatQualifiers.CustomerFacingMPO.value);
                        break;
                    case SeatQualifiers.CustomerFacingSIPR.key:
                        formattedQualifiers.push(SeatQualifiers.CustomerFacingSIPR.value);
                        break;
                    case SeatQualifiers.PhoneAtDesk.key:
                        formattedQualifiers.push(SeatQualifiers.PhoneAtDesk.value);
                        break;
                    case SeatQualifiers.SupportDesk.key:
                        formattedQualifiers.push(SeatQualifiers.SupportDesk.value);
                        break;
                }
            });
        }
        return formattedQualifiers;
    }

    const [reservationInstruction, setReservationInstruction] = useState<
        IReservationInstructions | undefined
    >(undefined);

    function handleMakingReservation(
        type: 'cancel' | 'reschedule' | 'schedule',
        facility: IFacilityRecord,
        makeReservations?: IMakeReservationInfo[],
        rescheduleReservations?: IRescheduleInfo[],
        cancelReservations?: ICancelationInfo[],
    ): void {
        // require no dialog for the kiosk if the available seat is general/hierarchy provision type
        setReservationInstruction({
            facility: facility,
            make: makeReservations,
            reschedule: rescheduleReservations,
            cancel: cancelReservations,
        });
        const icmDescription = '';
        if (authContext.isKioskRenderMode()) {
            // Running in kiosk mode so just do the requested work, no confirm dialogs.
            const conclusion: IReservationPromiseInstruction[] = [];
            if (makeReservations) {
                conclusion.push(
                    ...createMakePromises(
                        authContext,
                        userContext,
                        makeReservations,
                        icmDescription,
                    ),
                );
            }
            if (rescheduleReservations) {
                conclusion.push(
                    ...createReschedulePromises(authContext, userContext, rescheduleReservations),
                );
            }
            if (cancelReservations) {
                conclusion.push(
                    ...createCancelPromises(authContext, userContext, cancelReservations),
                );
            }
            Promise.all(conclusion.map((x) => x.promise)).then(() => {
                onModalConcluded(ModalConclusion.Done, conclusion);
            });
        } else if (
            !authContext.isKioskRenderMode()
            //TODO make livesite dialog work || makeReservations.seatInfo.provisionInfo.provisionType === ProvisionType.LiveSite
        ) {
            switch (type) {
                case 'cancel':
                    setCancelReservationDialogOpen(true);
                    break;
                case 'reschedule':
                    setChangeReservationDialogOpen(true);
                    break;
                case 'schedule':
                    setReservationDialogOpen(true);
                    break;
            }
        }
    }

    function cleanUp(): void {
        indoorManager?.dispose();
        dataSource?.dispose();
        map?.dispose();

        setIndoorManager(undefined);
        setDataSource(undefined);
        clearMapIfKiosk();
    }

    const [reservationPromiseInstructions, setReservationPromiseInstructions] = useState<
        IReservationPromiseInstruction[]
    >();

    function onModalConcluded(
        modalConclusion: ModalConclusion,
        reservationResults: IReservationPromiseInstruction[],
    ): void {
        if (modalConclusion === ModalConclusion.Done) {
            setReservationPromiseInstructions(reservationResults);
        }
        setChangeReservationDialogOpen(false);
        setCancelReservationDialogOpen(false);
        setReservationDialogOpen(false);
        setSelectedSeat(undefined);
    }

    useEffect(() => {
        if (reservationPromiseInstructions && props.updateFacilitySeatStatuses) {
            props.updateFacilitySeatStatuses(reservationPromiseInstructions);
        }
    }, [reservationPromiseInstructions]);

    const styles = mergeStyleSets({
        mapView: {
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 173px)',
        },
        postLoadingMapView: {
            display: 'none',
        },
    });

    function mapClass(): string {
        let mapClass = styles.mapView;

        if (props.featuresLoaded) {
            if (props.setPostLoadedClass) {
                mapClass = styles.postLoadingMapView;
            } else if (props.visible) {
                mapClass = styles.mapView;
            } else if (!props.visible) {
                mapClass = styles.postLoadingMapView;
            }
        } else if (props.showIndoorMapView) {
            if (props.setPostLoadedClass) {
                mapClass = styles.postLoadingMapView;
            } else {
                if (props.visible) {
                    mapClass = styles.mapView;
                } else {
                    mapClass = styles.postLoadingMapView;
                }
            }
        }

        return mapClass;
    }

    return (
        <div className={mapClass()}>
            <div
                ref={divRef}
                id={props.mapContainerId ?? defaultMapContainerId}
                style={{
                    position: 'relative',
                    height: '100%',
                    border: '1px solid #EDEBE9',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    backgroundColor: props.theme
                        ? AzureMapThemeColor[props.theme]
                        : AzureMapThemeColor.light,
                    visibility: props.visible ? 'visible' : 'hidden',
                }}>
                {props.children}
                {isReservationDialogOpen && reservationInstruction && (
                    <FacilitiesReservationModalV2
                        reservationInstructions={reservationInstruction}
                        onModalConcluded={onModalConcluded}
                        show={isReservationDialogOpen}
                        iseatRecords={eventObjRef.current.facilitySeats ?? []}
                    />
                )}
                {isCancelReservationDialogOpen && reservationInstruction && (
                    <CancelReservationModalActionButton
                        reservationInstructions={reservationInstruction}
                        iseatRecords={eventObjRef.current.facilitySeats ?? []}
                        onModalConcluded={onModalConcluded}
                        renderWithoutButton={true}
                    />
                )}
                {isChangeReservationDialogOpen && reservationInstruction && (
                    <ChangeReservationModal
                        reservationInstructions={reservationInstruction}
                        iseatRecords={eventObjRef.current.facilitySeats ?? []}
                        onModalConcluded={onModalConcluded}
                    />
                )}
            </div>
        </div>
    );
}
