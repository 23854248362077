import { useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
// This common file has been updated to handle UserContext not always being present
// eslint-disable-next-line no-restricted-imports
import { IUserContext, UserContext } from 'contexts/user-context';
// eslint-disable-next-line no-restricted-imports -- useCanShowContractAdminPage degrades gracefully when UserContext is not present
import { useCanShowContractAdminPage } from 'components/screening/screening-utils';
import { Role } from 'configs/roles';
import { FeatureFlagKeys, FeatureFlagResponse, useFeatureFlag } from 'utils/use-feature-flags';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { FacilityUserType } from 'utils/facilities-utils';
// eslint-disable-next-line no-restricted-imports -- only used when UserContext is present
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { GroupsUserContext } from 'contexts/user-contexts/groups-user-context';

type FeatureFlagSubset = Pick<
    typeof FeatureFlagKeys,
    | 'emailsCore'
    | 'formsCore'
    | 'profileVisitor'
    | 'profileUsGov'
    | 'screeningCore'
    | 'scaCore'
    | 'facilitiesCore'
    | 'readinessCore'
    | 'staffingCore'
    | 'groupsCore'
    | 'screeningContracts'
    | 'employeeCore'
    | 'supportLinkToAka'
    | 'personnelCoreAttributes'
>;

type MenuFlags = Record<keyof FeatureFlagSubset, FeatureFlagResponse>;

export function MenuAuthorization() {
    const userContext = useContext(UserContext) as IUserContext | null;
    const authContext = useContext(AuthContext);
    const groupsUserContext = useContext(GroupsUserContext);

    const msalUser = authContext.getUserProfile();
    const userRoles = msalUser && msalUser.roles ? msalUser.roles : [];
    const canShowContractAdminPage = useCanShowContractAdminPage(authContext);
    function isPermitted(userRoles: string[], requiredPermissions: Role[]): boolean {
        if (!Array.isArray(userRoles) || !userRoles.length) return false;
        if (userRoles.includes(Role.PortalAdmin)) return true;
        return requiredPermissions.some((item) => userRoles.includes(item));
    }

    const menuFlags: MenuFlags = {
        emailsCore: useFeatureFlag(FeatureFlagKeys.emailsCore),
        formsCore: useFeatureFlag(FeatureFlagKeys.formsCore),
        profileVisitor: useFeatureFlag(FeatureFlagKeys.profileVisitor),
        profileUsGov: useFeatureFlag(FeatureFlagKeys.profileUsGov),
        screeningCore: useFeatureFlag(FeatureFlagKeys.screeningCore),
        scaCore: useFeatureFlag(FeatureFlagKeys.scaCore),
        staffingCore: useFeatureFlag(FeatureFlagKeys.staffingCore),
        facilitiesCore: useFeatureFlag(FeatureFlagKeys.facilitiesCore),
        readinessCore: useFeatureFlag(FeatureFlagKeys.readinessCore),
        groupsCore: useFeatureFlag(FeatureFlagKeys.groupsCore),
        screeningContracts: useFeatureFlag(FeatureFlagKeys.screeningContracts),
        employeeCore: useFeatureFlag(FeatureFlagKeys.employeeCore),
        supportLinkToAka: useFeatureFlag(FeatureFlagKeys.supportLinkToAka),
        personnelCoreAttributes: useFeatureFlag(FeatureFlagKeys.personnelCoreAttributes),
    };

    const showProfile = menuFlags.employeeCore.enabled;

    const showProfileAssignments = isPermitted(userRoles, [Role.PersonnelAssignmentRead]);
    const showProfileVisitors =
        menuFlags.profileVisitor.enabled && isPermitted(userRoles, [Role.VisitorRecordRead]);
    const showProfileUSGov =
        menuFlags.profileUsGov.enabled &&
        !!userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.NST);
    const showProfileManageAttributes = isPermitted(userRoles, [
        Role.EligibilitiesRead,
        Role.AttributesRead,
    ]);

    const showScreening = menuFlags.screeningCore.enabled;
    const showScreeningContracts = menuFlags.screeningContracts.enabled;

    const showCloudMyOrg =
        !!userContext?.hasCloudScreeningUserType(CloudScreeningUserType.Manager) ||
        !!userContext?.hasCloudScreeningUserType(CloudScreeningUserType.ManagerDelegate) ||
        !!userContext?.hasCloudScreeningUserType(CloudScreeningUserType.Admin);
    const showCloudManage = !!userContext?.hasCloudScreeningUserType(CloudScreeningUserType.Admin);

    const showUSGovMyContracts = !!userContext?.hasUsGovScreeningUserType(
        UsGovScreeningUserType.ContractOwner,
    );
    const showUSGovMyNominees = !!userContext?.hasUsGovScreeningUserType(
        UsGovScreeningUserType.Nominator,
    );
    const showUSGovClearanceRecords =
        !!userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ||
        !!userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.ContractOwner);
    const showUSGovManage = !!userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.NST);

    const showPublicTrustMyContracts = !!userContext?.hasPublicTrustUserType(
        UsGovScreeningUserType.ContractOwner,
    );
    const showPublicTrustMyNominees = !!userContext?.hasPublicTrustUserType(
        UsGovScreeningUserType.Nominator,
    );
    const showPublicTrustManage = !!userContext?.hasPublicTrustUserType(UsGovScreeningUserType.NST);

    const showFacilitiesReservations =
        !!userContext?.isFacilitiesTokenLoaded &&
        !!userContext?.hasFacilitiesUserType(FacilityUserType.UserRole);
    const showFacilities = menuFlags.facilitiesCore.enabled && showFacilitiesReservations;
    const showFacilitiesManage =
        !!userContext?.isFacilitiesTokenLoaded &&
        (!!userContext?.hasFacilitiesUserType(FacilityUserType.AdminService) ||
            !!userContext?.hasFacilitiesUserType(FacilityUserType.ManagerRole));

    const showEmails =
        menuFlags.emailsCore.enabled &&
        isPermitted(userRoles, [
            Role.EmailRead,
            Role.EmailTemplateCreate,
            Role.EmailTemplateRead,
            Role.EmailTemplateDelete,
            Role.EmailTemplateModify,
        ]);
    const showEmailsSearch = isPermitted(userRoles, [Role.EmailRead]);
    const showEmailsTemplates = isPermitted(userRoles, [
        Role.EmailTemplateCreate,
        Role.EmailTemplateRead,
        Role.EmailTemplateDelete,
        Role.EmailTemplateModify,
    ]);
    const showEmailsManage = isPermitted(userRoles, [Role.EmailCoordinator]);

    const showForms = menuFlags.formsCore.enabled;

    const showSCA =
        menuFlags.scaCore.enabled &&
        (isPermitted(userRoles, [Role.SCAAdmin, Role.SCAExec]) ||
            !!userContext?.isScaMember ||
            !!userContext?.isScaManager);
    const showSCAMyHistory = !!userContext?.isScaMember;
    const showSCAMyTeam = !!userContext?.isScaManager;
    const showSCAMyOrg = isPermitted(userRoles, [Role.SCAExec]);
    const showSCAProgramDetails = !!userContext?.isScaManager;
    const showSCAManage = isPermitted(userRoles, [Role.SCAAdmin]);

    const showGroups =
        (menuFlags.groupsCore.enabled && isPermitted(userRoles, [Role.GroupAdmin])) ||
        (menuFlags.groupsCore.enabled && !!groupsUserContext?.isUserInAnyGroup);
    const showGroupsManage = isPermitted(userRoles, [Role.GroupAdmin]);

    const showStaffing =
        menuFlags.staffingCore.enabled &&
        isPermitted(userRoles, [
            Role.StaffingAdminEdit,
            Role.StaffingAdminRead,
            Role.StaffingOrgEdit,
            Role.StaffingOrgRead,
        ]);

    const showReadiness = menuFlags.readinessCore.enabled;

    return {
        canShowContractAdminPage,

        showAttributes: menuFlags.personnelCoreAttributes.enabled,

        showProfile,
        showProfileAssignments,
        showProfileVisitors,
        showProfileUSGov,
        showProfileManageAttributes,

        showScreening,
        showScreeningContracts,

        showCloudMyOrg,
        showCloudManage,

        showUSGovMyContracts,
        showUSGovMyNominees,
        showUSGovClearanceRecords,
        showUSGovManage,

        showPublicTrustMyContracts,
        showPublicTrustMyNominees,
        showPublicTrustManage,

        showFacilitiesReservations,
        showFacilities,
        showFacilitiesManage,

        showEmails,
        showEmailsSearch,
        showEmailsTemplates,
        showEmailsManage,

        showForms,

        showSCA,
        showSCAMyHistory,
        showSCAMyTeam,
        showSCAMyOrg,
        showSCAProgramDetails,
        showSCAManage,

        showGroups,
        showGroupsManage,

        showStaffing,

        showReadiness,

        shouldUseSupportLinkToAka: menuFlags.supportLinkToAka.enabled,
    };
}
