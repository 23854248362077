import { maxWidthCoeff } from 'assets/constants/global-constants';
import FacilitiesClient, { IFacilityRecord, ILogBookRecord } from 'clients/facilities-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { Table, TableCell } from 'components/common/table';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { IColumn, IDropdownOption, mergeStyles, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useIsMounted } from 'utils/misc-hooks';
import { TimeFormats } from 'utils/time-utils';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import SecureWorkAreaDropdownSelector from 'components/facilities/common/secure-work-area-dropdown-selector';
import AddLogBookEntry from 'components/facilities/facilities-logbook/modals/add-logbook-entry-modal';
import DeleteLogBookEntryModal from 'components/facilities/facilities-logbook/modals/delete-logbook-entry-modal';

export interface FacilitiesManagementLogBookPageProps {
    facilities: IFacilityRecord[] | undefined;
}

export default function FacilitiesManagementLogbook(
    props: FacilitiesManagementLogBookPageProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [selectedFacilityId, setSelectedFacilityId] = useState<string>();
    const [logBookRecords, setLogBookRecords] = useState<ILogBookRecord[]>();
    const isMounted = useIsMounted();

    useEffect(() => {
        if (props.facilities && props.facilities.length > 0) {
            setSelectedFacilityId(props.facilities[0].id);

            getLogBookRecords(props.facilities[0].id);
        }
    }, props.facilities);

    async function facilitySelectionChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number,
    ): Promise<void> {
        if (option) {
            setSelectedFacilityId(option.key.toString());

            await getLogBookRecords(option.key.toString());
        }
    }

    async function updateLogBookRecords(logBookRecord: ILogBookRecord) {
        if (!logBookRecord) {
            return;
        }

        if (isMounted()) {
            setLogBookRecords((logBookRecords) => {
                const newRecords = logBookRecords?.filter((x) => x.id !== logBookRecord.id);
                return newRecords;
            });
        }
    }

    async function updateLogBookRecordsOnAdd(logBookRecord: ILogBookRecord) {
        if (!logBookRecord) {
            return;
        }

        if (isMounted()) {
            setLogBookRecords((oldLogBookRecords) => {
                const newLogBookRecords: ILogBookRecord[] = [...(oldLogBookRecords ?? [])];

                newLogBookRecords.push(logBookRecord);
                return newLogBookRecords;
            });
        }
    }

    async function getLogBookRecords(facilityId: string | undefined): Promise<void> {
        if (facilityId) {
            const facilityLogBookRecords: ILogBookRecord[] = [];

            let continuationToken = '';

            do {
                const logBookRecordsResults = await FacilitiesClient.getAllLogBookRecords(
                    authContext,
                    userContext,
                    facilityId,
                    continuationToken,
                );

                logBookRecordsResults.results.forEach((x) => {
                    facilityLogBookRecords.push(x);
                });

                continuationToken = logBookRecordsResults.continuationToken ?? '';
            } while (continuationToken);
            if (isMounted()) {
                setLogBookRecords(facilityLogBookRecords);
            }
        }
    }

    enum logBookColumnNames {
        Employee = 'Employee',
        SignedInAt = 'Signed In At',
        SignedInBy = 'Signed In By',
        Actions = 'Actions',
    }

    const columnWidths = {
        employee: 250,
        signedInAt: 100,
        signedInBy: 250,
        actions: 100,
    };

    const columns: IColumn[] = [
        {
            key: logBookColumnNames.Employee,
            name: logBookColumnNames.Employee,
            ariaLabel: logBookColumnNames.Employee,
            minWidth: columnWidths.employee,
            maxWidth: columnWidths.employee * maxWidthCoeff,
            onRender: (row: ILogBookRecord): JSX.Element => {
                if (!row.personnelId) {
                    return <></>;
                }

                return (
                    <EmployeeBasicHoverCard key={row.personnelId} personnelId={row.personnelId} />
                );
            },
        },
        {
            key: logBookColumnNames.SignedInAt,
            name: logBookColumnNames.SignedInAt,
            ariaLabel: logBookColumnNames.SignedInAt,
            minWidth: columnWidths.signedInAt,
            maxWidth: columnWidths.signedInAt * maxWidthCoeff,
            onRender: (row: ILogBookRecord): JSX.Element => {
                return (
                    <TableCell>
                        {handleUTCToFacilityTimeZone(
                            row.createdTimestamp.atUtc,
                            props.facilities?.find((x) => x.id === selectedFacilityId),
                            TimeFormats.MMMDDYYYY_hmmA,
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: logBookColumnNames.SignedInBy,
            name: logBookColumnNames.SignedInBy,
            ariaLabel: logBookColumnNames.SignedInBy,
            minWidth: columnWidths.signedInBy,
            maxWidth: columnWidths.signedInBy * maxWidthCoeff,
            onRender: (row: ILogBookRecord): JSX.Element => {
                if (!row.createdTimestamp.by) {
                    return <></>;
                }

                return (
                    <EmployeeBasicHoverCard
                        key={row.createdTimestamp.by}
                        personnelId={row.createdTimestamp.by}
                    />
                );
            },
        },
        {
            key: logBookColumnNames.Actions,
            name: logBookColumnNames.Actions,
            ariaLabel: logBookColumnNames.Actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * maxWidthCoeff,
            onRender: (row: ILogBookRecord): JSX.Element => {
                return (
                    <TableCell>
                        <div className={mergeStyles({ display: 'inline-block' })}>
                            <DeleteLogBookEntryModal
                                facility={props.facilities?.find(
                                    (x) => x.id === selectedFacilityId,
                                )}
                                logBookRecord={row}
                                updateLogBookOnDelete={updateLogBookRecords}
                            />
                        </div>
                    </TableCell>
                );
            },
        },
    ];

    return (
        <>
            <SidebarAndContents>
                <SidebarPane>
                    <SecureWorkAreaDropdownSelector
                        onChange={facilitySelectionChange}
                        placeholder='Select a facility'
                        selectedKey={selectedFacilityId}
                        options={props.facilities?.map((f) => {
                            return {
                                key: f.id,
                                text: f.facilityName,
                            };
                        })}
                    />
                </SidebarPane>
                <ContentPane>
                    <HorizontalBar>
                        <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                            Log Book
                        </Stack.Item>
                    </HorizontalBar>
                    <AddLogBookEntry
                        facilityId={selectedFacilityId}
                        updateLogBookOnAdd={updateLogBookRecordsOnAdd}
                    />
                    <Table
                        isFetchingData={false}
                        rows={logBookRecords ?? []}
                        tableName='Facility Log Book'
                        tableColumns={columns}
                        shimmerLines={2}
                        shimmerLabel='Loading log book...'
                    />
                </ContentPane>
            </SidebarAndContents>
        </>
    );
}
