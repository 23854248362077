import { IColumn, mergeStyleSets, Stack, TooltipHost } from '@fluentui/react';
import {
    maxWidthCoeff,
    xLargeMaxWidthCoeff,
    xxLargeMaxWidthCoeff,
} from 'assets/constants/global-constants';
import { AddedByType, GroupRole, IGroup, IGroupMembership } from 'clients/group-client';
import Badge from 'components/common/badge';
import { TableCell } from 'components/common/table';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import ApplicationGroupDisplay from 'components/groups/application-group-display/application-group-display';
import ManageGroupMemberDetailsModalActionButton from 'components/groups/common/manage-group-member-details-modal-action-button';
import {
    getCompliantColor,
    getCompliantStatus,
    ModalAddEditType,
} from 'components/groups/groups-utils';
import AddEditRoleModalActionButton from 'components/groups/manage-group/roles/buttons/add-edit-role-modal-action-button';
import DemoteRoleModalActionButton from 'components/groups/manage-group/roles/buttons/demote-role-modal-action-button';
import React from 'react';
import { ISortableColumns } from 'utils/sort-utils';
import { toTitleCase } from 'utils/string-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

export enum OwnershipsTableColumnNames {
    name = 'Name',
    role = 'Role',
    status = 'Status',
    modifiedBy = 'Modified By',
    dateModified = 'Date Modified',
    actions = 'Actions',
}

interface ITableColumnsGroupOwnershipsParams extends ISortableColumns {
    enableOwnerDemote: boolean;
    group: IGroup;
    canTouchRoleOrApp: boolean;
    onRoleAddOrUpdate: (role: IGroupMembership, mode: ModalAddEditType) => void;
    onRoleDemoted: (personnelId: string) => void;
}

export function tableColumnsGroupOwnerships(params: ITableColumnsGroupOwnershipsParams): IColumn[] {
    const deleteColumnWidth = params.canTouchRoleOrApp ? 80 : 0;
    const columnWidths = {
        name: 100,
        role: 40,
        status: 90,
        modifiedBy: 100,
        dateModified: 130,
        actions: 180 + deleteColumnWidth,
    };

    return [
        {
            key: OwnershipsTableColumnNames.name,
            name: OwnershipsTableColumnNames.name,
            ariaLabel: OwnershipsTableColumnNames.name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xxLargeMaxWidthCoeff,
            isSorted: params.sortColumn === OwnershipsTableColumnNames.name,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(OwnershipsTableColumnNames.name);
            },
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${OwnershipsTableColumnNames.name}`}>
                    <CoreEmployeeHoverCardFromPrincipalId principalId={row.personnelId} />
                </TableCell>
            ),
        },
        {
            key: OwnershipsTableColumnNames.role,
            name: OwnershipsTableColumnNames.role,
            ariaLabel: OwnershipsTableColumnNames.role,
            minWidth: columnWidths.role,
            maxWidth: columnWidths.role * maxWidthCoeff,
            isSorted: params.sortColumn === OwnershipsTableColumnNames.role,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(OwnershipsTableColumnNames.role);
            },
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${OwnershipsTableColumnNames.role}`}>
                    {toTitleCase(row.role)}
                </TableCell>
            ),
        },
        {
            key: OwnershipsTableColumnNames.status,
            name: OwnershipsTableColumnNames.status,
            ariaLabel: OwnershipsTableColumnNames.status,
            minWidth: columnWidths.status,
            maxWidth: columnWidths.status * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === OwnershipsTableColumnNames.status,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(OwnershipsTableColumnNames.status);
            },
            onRender: (row: IGroupMembership): JSX.Element => {
                const status = getCompliantStatus(!!row.compliant, false, false);
                const graceStatus = getCompliantStatus(!!row.compliant, true, false);
                return (
                    <TableCell key={`${row.id}-${OwnershipsTableColumnNames.status}`}>
                        <Stack horizontal wrap>
                            <TooltipHost content={status}>
                                <Badge
                                    text={status}
                                    backgroundColor={getCompliantColor(
                                        !!row.compliant,
                                        false,
                                        false,
                                    )}
                                />
                            </TooltipHost>
                            {row.compliant && row.hasViolationOnGracePeriod && (
                                <>
                                    <span className={styles.graceBadgeGap}></span>
                                    <TooltipHost content={graceStatus}>
                                        <div>
                                            <Badge
                                                text={graceStatus}
                                                backgroundColor={getCompliantColor(
                                                    !!row.compliant,
                                                    true,
                                                    false,
                                                )}
                                            />
                                        </div>
                                    </TooltipHost>
                                </>
                            )}
                        </Stack>
                    </TableCell>
                );
            },
        },
        {
            key: OwnershipsTableColumnNames.modifiedBy,
            name: OwnershipsTableColumnNames.modifiedBy,
            ariaLabel: OwnershipsTableColumnNames.modifiedBy,
            minWidth: columnWidths.modifiedBy,
            maxWidth: columnWidths.modifiedBy * xxLargeMaxWidthCoeff,
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${OwnershipsTableColumnNames.modifiedBy}`}>
                    {row.modifiedByType === AddedByType.USER && (
                        <CoreEmployeeHoverCardFromPrincipalId principalId={row.modifiedBy} />
                    )}
                    {row.modifiedByType === AddedByType.APPLICATION && (
                        <ApplicationGroupDisplay
                            groupId={row.groupId}
                            groupName={params.group.name}
                            applicationId={row.modifiedBy}
                        />
                    )}
                </TableCell>
            ),
        },
        {
            key: OwnershipsTableColumnNames.dateModified,
            name: OwnershipsTableColumnNames.dateModified,
            ariaLabel: OwnershipsTableColumnNames.dateModified,
            minWidth: columnWidths.dateModified,
            maxWidth: undefined,
            isSorted: params.sortColumn === OwnershipsTableColumnNames.dateModified,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(OwnershipsTableColumnNames.dateModified);
            },
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${OwnershipsTableColumnNames.dateModified}`}>
                    {timeToString(row.modifiedTimestampUTC * 1000, TimeFormats.MMMDDYYYY_hmmA)}
                </TableCell>
            ),
        },
        {
            key: OwnershipsTableColumnNames.actions,
            name: OwnershipsTableColumnNames.actions,
            ariaLabel: OwnershipsTableColumnNames.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions,
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${OwnershipsTableColumnNames.actions}`}>
                    <Stack horizontal>
                        <ManageGroupMemberDetailsModalActionButton memberDetails={row} />
                        {params.canTouchRoleOrApp && (
                            <AddEditRoleModalActionButton
                                enableOwnerDemote={params.enableOwnerDemote}
                                mode={ModalAddEditType.update}
                                group={params.group}
                                member={row}
                                onRoleAddOrUpdate={params.onRoleAddOrUpdate}
                                canTouchRoleOrApp={params.canTouchRoleOrApp}
                            />
                        )}
                        {params.canTouchRoleOrApp && (
                            <DemoteRoleModalActionButton
                                enable={row.role !== GroupRole.OWNER || params.enableOwnerDemote}
                                memberDetails={row}
                                onRoleDemoted={params.onRoleDemoted}
                            />
                        )}
                    </Stack>
                </TableCell>
            ),
        },
    ];
}

const styles = mergeStyleSets({
    graceBadgeGap: {
        width: 10,
    },
});
