import React, { useContext, useEffect, useState } from 'react';
import { indoor } from 'azure-maps-indoor';
import atlas, { data, layer, Map as AzureMap } from 'azure-maps-control'; // Referencing 'atlas' import directly seems to sometimes not work (ie. atlas.source.DateSource)
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import {
    changeMap,
    createAzureMap,
    FacilitiesAzureIndoorMapBaseProps,
    getFacilitySeatDict,
    getFeatureCenterPosition,
    getIndoorManagerOptions,
    defaultMapContainerId,
    AzureMapThemeColor,
    addZoomControl,
} from 'components/facilities/facilities-azure-indoor-map/facilities-azure-indoor-map-common';
import { ISeatRecord } from 'clients/facilities-client';
import EmployeeClient from 'clients/employee-client';
import { fetchPictures } from 'components/common/employee/employee-utils';

export type FacilitiesAzureIndoorMapDisplayProps = FacilitiesAzureIndoorMapBaseProps;

const seatOccupancyImageLayerId = 'seat-occupancy-image-layer-id';
const imageDistanceFromCenterInDegrees = 0.000012;

export default function FacilitiesAzureIndoorMapDisplay(
    props: FacilitiesAzureIndoorMapDisplayProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [map, setMap] = useState<AzureMap>();
    const [indoorManager, setIndoorManager] = useState<indoor.IndoorManager>();
    const [hasAllFeaturesLoaded, setAllFeaturesLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (props.facility) {
            generateMap();
        }
    }, [props.facility]);

    useEffect(() => {
        if (props.facilitySeats) {
            createSeatOccupancyImageLayers(props.facilitySeats);
        }
    }, [props.facilitySeats]);

    useEffect(() => {
        if (map && props.onFeaturesLoaded) {
            const featureCount = { prev: -1, curr: -1 };
            const interval = setInterval(() => {
                if (hasFeaturesLoaded()) {
                    featureCount.curr = map.layers.getRenderedShapes().length;
                    if (featureCount.prev > -1 && featureCount.prev === featureCount.curr) {
                        // all features have been loaded from indoor map tileset
                        setAllFeaturesLoaded(true);
                        props.onFeaturesLoaded && props.onFeaturesLoaded();
                        clearInterval(interval);
                    }

                    featureCount.prev = featureCount.curr;
                }
            }, 500);
            return () => clearInterval(interval);
        }
    }, [map]);

    // clean up resources when component unmounts from the DOM
    useEffect(() => {
        return () => cleanUp();
    }, []);

    // TODO: Should display employee hover cards (maybe via html marker) instead of employee pictures via image layers
    async function createSeatOccupancyImageLayers(seats?: ISeatRecord[]): Promise<void> {
        if (hasAllFeaturesLoaded && seats) {
            const seatDict = getFacilitySeatDict(seats);
            const occupiedSeatFeatures = map?.layers
                .getRenderedShapes()
                .filter(
                    (x) =>
                        (x as any).type?.toLowerCase() === 'feature' &&
                        (x as any).properties?.name &&
                        seatDict[(x as any).properties?.name.toLowerCase()] &&
                        seatDict[(x as any).properties?.name.toLowerCase()].occupiedBy?.by,
                )
                .map((x) => x as atlas.data.Feature<atlas.data.Geometry, any>);

            const occupiedSeatPersonnelIds = new Set<string>(
                occupiedSeatFeatures?.map(
                    (x) => seatDict[x.properties?.name.toLowerCase()].occupiedBy?.by ?? '',
                ),
            );

            try {
                const employeeRecords = await EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(occupiedSeatPersonnelIds),
                );
                const pictures = await fetchPictures(authContext, employeeRecords);

                // Remove image layers before we add new ones
                map?.layers.remove(
                    map.layers
                        .getLayers()
                        .filter((x) => x.getId().includes(seatOccupancyImageLayerId)),
                );

                occupiedSeatFeatures?.forEach((x) => {
                    const centerPosition = getFeatureCenterPosition(x);
                    const coordinates = [
                        new data.Position(
                            centerPosition[0] - imageDistanceFromCenterInDegrees,
                            centerPosition[1] + imageDistanceFromCenterInDegrees,
                        ),
                        new data.Position(
                            centerPosition[0] + imageDistanceFromCenterInDegrees,
                            centerPosition[1] + imageDistanceFromCenterInDegrees,
                        ),
                        new data.Position(
                            centerPosition[0] + imageDistanceFromCenterInDegrees,
                            centerPosition[1] - imageDistanceFromCenterInDegrees,
                        ),
                        new data.Position(
                            centerPosition[0] - imageDistanceFromCenterInDegrees,
                            centerPosition[1] - imageDistanceFromCenterInDegrees,
                        ),
                    ];

                    map?.layers.add([
                        new layer.ImageLayer(
                            {
                                url:
                                    pictures[
                                        seatDict[x.properties?.name.toLowerCase()].occupiedBy?.by ??
                                            ''
                                    ],
                                coordinates: coordinates,
                            },
                            `${seatOccupancyImageLayerId}-${
                                seatDict[x.properties?.name.toLowerCase()].id
                            }`,
                        ),
                    ]);
                });
            } catch (e) {
                console.error('Failed to create seat occupancy image layers');
            }
        }
    }

    function hasFeaturesLoaded(): boolean {
        return !!map && map.layers.getRenderedShapes().length > 0;
    }

    async function generateMap(): Promise<void> {
        if (!map) {
            await createMap();
        } else {
            changeMap(map, indoorManager, props.facility, props.theme);
        }
    }

    async function createMap(): Promise<void> {
        const newMap = await createAzureMap(authContext, userContext, props.facility);

        // Wait until the map resources are ready
        newMap.events.add('ready', () => {
            addIndoorManager(newMap);
            addZoomControl(newMap);

            // Change the cursor of the mouse when it is over the map to be a pointer
            newMap.getCanvasContainer().style.cursor = 'pointer';
        });

        setMap(newMap);
    }

    function addIndoorManager(azureMap: AzureMap): void {
        const newIndoorManager = new indoor.IndoorManager(
            azureMap as indoor.IMap,
            getIndoorManagerOptions(props.facility, props.theme),
        );

        setIndoorManager(newIndoorManager);
    }

    function cleanUp(): void {
        indoorManager?.dispose();
        map?.dispose();

        setIndoorManager(undefined);
        setMap(undefined);
    }

    return (
        <div
            id={defaultMapContainerId}
            style={{
                position: 'relative',
                height: '100%',
                border: '1px solid #EDEBE9',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                backgroundColor: props.theme
                    ? AzureMapThemeColor[props.theme]
                    : AzureMapThemeColor.light,
            }}></div>
    );
}
