import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import Tabs, { TabbedContent } from 'components/common/tabs';
import FacilitiesSearch, {
    ITimeRange,
} from 'components/facilities/facilities-reservations/facilities-search';
import FacilitiesClient, {
    IFacilityRecord,
    ISeatAvailabilityCalendarResponse,
    ISeatRecord,
    ReservationView,
    SeatQualifiers,
} from 'clients/facilities-client';
import { FacilitiesMenuNavigationText } from 'utils/facilities-utils';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import FacilitiesLegend from 'components/facilities/facilities-legend';
import FacilitiesAzureIndoorMap from 'components/facilities/facilities-azure-indoor-map/facilities-azure-indoor-map';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import FacilitiesUserReservationTableView from 'components/facilities/facilities-reservations/facilities-user-reservation-table-view/facilities-user-reservation-table-view';
import FacilitiesReservationViewToggle from 'components/facilities/facilities-reservations/facilities-reservation-view-toggle';
import FacilitiesReservationCalendar from 'components/facilities/facilities-reservations/facilities-reservation-calendar/facilities-reservation-calendar';
import { MessageBarType } from '@fluentui/react';
import useMessageBar from 'components/common/use-message-bar';
import { useHistory, useParams } from 'react-router-dom';
import {
    ICheckboxArrayV1Props,
    useCheckboxArrayV1,
} from 'components/common/use-input/use-checkbox-array-v1';
import { removeWhiteSpaceFromString } from 'utils/string-utils';
import moment, { Moment } from 'moment';
import { DefaultTimeZone } from 'components/facilities/common/facility-time-utils';
import {
    IReservationPromiseInstruction,
    applyCancelInstruction,
    applyMakeInstruction,
    applyRescheduleInstruction,
} from 'components/facilities/facilities-reservations/modals/facilities-reservation-instruction-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import FacilitiesSvgIndoorMap from 'components/facilities/facilities-svg-map/facilities-svg-indoor-map';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FacilitiesPageProps {
    match: {
        params: {
            category?: string;
        };
    };
}

export interface ITimeAvailability {
    facilityId: string;
    startTime: number; // Date and time when treating as UTC are correct for facility timezone
    endTime: number; // Date and time when treating as UTC are correct for facility timezone
    calendarResponses: Promise<ISeatAvailabilityCalendarResponse[]>;
}

const newReservationBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: 'Facilities',
        link: '',
    },
    {
        title: 'Reservations',
        link: '',
    },
    {
        title: 'New Reservation',
        link: '',
    },
];

const myReservationsBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: 'Facilities',
        link: '',
    },
    {
        title: 'Reservations',
        link: '',
    },
    {
        title: 'My Reservations',
        link: '',
    },
];

const categoryLabels = {
    NewReservation: {
        text: 'new',
        index: '0',
    },
    MyReservations: {
        text: 'my',
        index: '1',
    },
};

const categories = [categoryLabels.NewReservation, categoryLabels.MyReservations];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function FacilitiesPage(): JSX.Element {
    const isSvgMapEnabled = useFeatureFlag(FeatureFlagKeys.enableSvgMap).enabled;
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const history = useHistory();
    const { category } = useParams<{ category?: string }>();
    const [facilities, setFacilities] = useState<IFacilityRecord[]>([]);

    const [selectedFacility, setSelectedFacility] = useState<IFacilityRecord>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedTimeRange, setSelectedTimeRange] = useState<ITimeRange>();

    const [selectedFacilitySeats, setSelectedFacilitySeats] = useState<ISeatRecord[]>([]);
    const [unfilteredSeatRecords, setUnfilteredSeatRecords] = useState<ISeatRecord[]>([]);

    const [reservationView, setReservationView] = useState<ReservationView>(ReservationView.Map);
    const [hasFeaturesLoaded, setFeaturesLoaded] = useState<boolean>(false);
    const [isReserveButtonClicked, setIsReserveButtonClicked] = useState<boolean>(false);
    const [isReserveButtonEnabled, setIsReserveButtonEnabled] = useState<boolean>(false);
    const [isBlockedFromReserving, setIsBlockedFromReserving] = useState<boolean>(false);

    const shouldUseSvgMap = isSvgMapEnabled && !!selectedFacility?.svgMap;

    const blockedReserveAheadAppealEmailSubject = encodeURI(
        `Request to Unblock Reserve Ahead at Facility: ${selectedFacility?.facilityName}`,
    );
    const blockedReserveAheadAppealEmailBody = encodeURI(
        `Hello\n\nMy privilege to reserve ahead for facility: ${selectedFacility?.facilityName} has been revoked due to missing too many reservations. I am requesting the ability to reserve ahead again at this facility.\n\nThanks\n${userContext.employeeRecord.fullName}`,
    );
    const blockedReserveAheadAppealEmail = `mailto:${
        selectedFacility?.facilitySupportPointOfContact ?? 'personnel_pm@microsoft.com'
    }?subject=${blockedReserveAheadAppealEmailSubject}&body=${blockedReserveAheadAppealEmailBody}`;

    const updateBreadcrumbsCallback = useCallback(
        (categoryText?: string) => {
            if (!categoryText) {
                return;
            }

            const breadcrumbs =
                categoryText === categoryLabels.NewReservation.text
                    ? newReservationBreadcrumbs
                    : myReservationsBreadcrumbs;
            breadCrumbContext.setBreadCrumbs(breadcrumbs);
        },
        [breadCrumbContext],
    );

    const normalizeDates = useCallback((): { startTime: Moment; endTime: Moment } => {
        const timezone = selectedFacility?.timeZone ?? DefaultTimeZone;

        const startDateHour = selectedTimeRange!.startTime.hour();
        const startDateMinute = selectedTimeRange!.startTime.minute();

        const endDateHour = selectedTimeRange!.endTime.hour();
        const endDateMinute = selectedTimeRange!.endTime.minute();

        const startDate = moment()
            .tz(timezone)
            .hour(startDateHour)
            .minute(startDateMinute)
            .date(selectedDate.getDate())
            .month(selectedDate.getMonth())
            .year(selectedDate.getFullYear())
            .second(0)
            .millisecond(0);

        let endDate = moment()
            .tz(timezone)
            .hour(endDateHour)
            .minute(endDateMinute)
            .date(selectedDate.getDate())
            .month(selectedDate.getMonth())
            .year(selectedDate.getFullYear())
            .second(0)
            .millisecond(0);

        if (endDate.valueOf() < startDate.valueOf()) {
            endDate = moment(endDate).add(1, 'day');
        }

        return {
            startTime: startDate,
            endTime: endDate,
        };
    }, [selectedDate, selectedFacility, selectedTimeRange]);

    useEffect(() => {
        updateBreadcrumbsCallback(category);
    }, [category, updateBreadcrumbsCallback]);

    const getFacilityCalendarAvailability = useCallback(async (): Promise<
        ISeatAvailabilityCalendarResponse[]
    > => {
        const calendarResponses: ISeatAvailabilityCalendarResponse[] = [];

        const useDates = normalizeDates();

        try {
            let continuationToken = '';
            do {
                const seatAvailability = await FacilitiesClient.getSeatAvailabilityForCalendar(
                    authContext,
                    userContext,
                    selectedFacility!.id,
                    useDates.startTime.toDate(),
                    useDates.endTime.toDate(),
                    continuationToken,
                    true,
                );
                calendarResponses.push(seatAvailability);

                continuationToken = seatAvailability.continuationToken;
            } while (continuationToken);
        } catch (error) {
            console.log('Failed to retrieve seat calendar availability for facility');
        }
        return calendarResponses;
    }, [normalizeDates, selectedFacility, authContext, userContext]);

    useEffect(() => {
        const getFacilities = async (): Promise<void> => {
            try {
                const newFacilities = await FacilitiesClient.getFacilityRecords(
                    authContext,
                    userContext,
                );

                setFacilities(newFacilities);
                setSelectedFacility(newFacilities.length > 0 ? newFacilities[0] : undefined);
            } catch (error) {
                console.log('Failed to retrieve facilities', error);
            }
        };

        if (userContext.isFacilitiesTokenLoaded) {
            getFacilities();
        }
    }, [authContext, userContext, userContext.isFacilitiesTokenLoaded]);

    useEffect(() => {
        const getFacilitySeats = async (facilityId: string): Promise<void> => {
            try {
                const newSelectedFacilitySeats: ISeatRecord[] = [];

                let continuationToken = '';
                do {
                    const seatsPagedResults = await FacilitiesClient.getSeatsByFacilityId(
                        authContext,
                        userContext,
                        facilityId,
                        continuationToken,
                    );

                    seatsPagedResults.results.forEach((x) => {
                        newSelectedFacilitySeats.push(x);
                    });
                    continuationToken = seatsPagedResults.continuationToken ?? '';
                } while (continuationToken);

                setUnfilteredSeatRecords(newSelectedFacilitySeats);
            } catch (error) {
                console.log('Failed to retrieve seats for facility');
            }
        };

        if (selectedFacility) {
            getFacilitySeats(selectedFacility.id);
        }
    }, [authContext, selectedFacility, userContext]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function reservationViewChange(event: React.MouseEvent<HTMLElement>): void {
        setFeaturesLoaded(false);
        setReservationView((prevReservationView) =>
            prevReservationView === ReservationView.Calendar
                ? ReservationView.Map
                : ReservationView.Calendar,
        );
    }

    const timeSlotStartTime = selectedTimeRange?.startTime;
    const timeSlotEndTime = selectedTimeRange?.endTime;

    const [timeAvailability, setTimeAvailability] = useState<ITimeAvailability | undefined>(
        undefined,
    );

    useEffect(() => {
        if (timeSlotStartTime && timeSlotEndTime && selectedFacility && selectedDate) {
            const calendarResponses = getFacilityCalendarAvailability();

            const useDates = normalizeDates();

            setTimeAvailability({
                facilityId: selectedFacility.id,
                startTime: useDates.startTime.utc().valueOf(),
                endTime: useDates.endTime.utc().valueOf(),
                calendarResponses: calendarResponses,
            });
        }
    }, [
        timeSlotStartTime,
        timeSlotEndTime,
        selectedFacility,
        selectedDate,
        getFacilityCalendarAvailability,
        selectedTimeRange,
        normalizeDates,
    ]);

    const facilitySearch = useMemo((): JSX.Element => {
        return (
            <FacilitiesSearch
                facilities={facilities}
                selectedFacility={selectedFacility}
                selectedDate={selectedDate}
                selectedTimeRange={selectedTimeRange}
                updateSelectedFacility={setSelectedFacility}
                updateSelectedDate={setSelectedDate}
                updateSelectedTimeRange={setSelectedTimeRange}
                reservationView={reservationView}
                setIsReserveButtonClickedProp={setIsReserveButtonClicked}
                isReserveButtonEnabled={isReserveButtonEnabled}
            />
        );
    }, [
        facilities,
        selectedFacility,
        selectedDate,
        selectedTimeRange,
        reservationView,
        isReserveButtonEnabled,
    ]);

    const facilityReservationView = (): JSX.Element => {
        return (
            <FacilitiesReservationViewToggle
                toggled={reservationView === ReservationView.Calendar}
                onChange={reservationViewChange}
                styles={{ root: { marginTop: '-41px', alignSelf: 'flex-end' } }}
                label={ReservationView.Map}
                onOffText={ReservationView.Calendar}
            />
        );
    };

    const getDefaultTabKey = useCallback(() => {
        switch (category) {
            case categoryLabels.MyReservations.text:
                return categoryLabels.MyReservations.index;
            case categoryLabels.NewReservation.text:
            default:
                return categoryLabels.NewReservation.index;
        }
    }, [category]);

    function onTabChange(key?: string): void {
        if (!key) {
            return;
        }

        setFeaturesLoaded(false);

        const selectedCategory = categories.find((x) => x.index === key);
        if (selectedCategory) {
            history.push(selectedCategory.text);
            updateBreadcrumbsCallback(selectedCategory.text);
        }
    }

    function contentPaneStyle(): React.CSSProperties {
        if (reservationView === ReservationView.Map) {
            return mapStyle;
        } else {
            return calendarStyle;
        }
    }

    function isViewOnly(): boolean {
        return isBlockedFromReserving;
        /*
            selectedFacilitySeatStatuses.some(
                (x) =>
                    x.status === SeatStatuses.MySeat.value &&
                    x.reservationState === ReservationState.CheckedIn,
            ) || isBlockedFromReserving
        );
        */
    }

    const calendarStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
    };

    const mapStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        height: 'max(calc(100vh - 790px), 600px)',
    };

    const {
        value: seatQualifiers,
        initialize: initSeatQualifiers,
        theElement: seatQualifiersElement,
    } = useCheckboxArrayV1({
        label: 'Seat Qualifiers',
        isLabelSeparator: true,
        options: initSeatQualifierOptions(),
    });

    function initSeatQualifierOptions(): ICheckboxArrayV1Props[] {
        return [
            {
                label: SeatQualifiers.CustomerFacingAVC.value,
                isChecked: false,
            },
            {
                label: SeatQualifiers.CustomerFacingMPO.value,
                isChecked: false,
            },
            {
                label: SeatQualifiers.CustomerFacingSIPR.value,
                isChecked: false,
            },
            {
                label: SeatQualifiers.SupportDesk.value,
                isChecked: false,
            },
            {
                label: SeatQualifiers.PhoneAtDesk.value,
                isChecked: false,
            },
        ];
    }

    useEffect(() => {
        // filter through the seatrecords to see if any have the seat qualifiers selected
        // those seats with none/not the qualifiers selected are updated to appear Unavailable
        const selectedSeatQualifiers = seatQualifiers
            ?.filter((sq) => sq.isChecked)
            .map((sq) => removeWhiteSpaceFromString(sq.label));

        let filteredSeatStatuses = unfilteredSeatRecords;
        if (selectedSeatQualifiers && selectedSeatQualifiers?.length > 0) {
            filteredSeatStatuses = unfilteredSeatRecords.filter((s) => {
                const seatQualifiers = s.qualifiers.map((q) => removeWhiteSpaceFromString(q));
                return selectedSeatQualifiers.every((ssa) => seatQualifiers.includes(ssa));
            });
        }
        setSelectedFacilitySeats(filteredSeatStatuses);
    }, [seatQualifiers, unfilteredSeatRecords]);

    const {
        theMessage: missedReservationMessage,
        theElement: MissedReservationMessageBar,
        setMessage: setMissedReservationMessageBarMessage,
    } = useMessageBar({
        type: MessageBarType.severeWarning,
        disableClear: true,
        alwaysShow: true,
        linkText: 'here.',
        url: blockedReserveAheadAppealEmail,
    });

    useEffect(() => {
        async function checkIfBlockedFromReservingAhead(): Promise<void> {
            try {
                await FacilitiesClient.getBlockedFacilityUserRecordById(
                    authContext,
                    userContext,
                    selectedFacility?.facilityId ?? '',
                );

                setIsBlockedFromReserving(true);
                setIsReserveButtonEnabled(false);
                setMissedReservationMessageBarMessage(
                    `You have missed too many reservations for this facility and are blocked from reserving ahead. Please contact the facility support point of contact to resolve the issue`,
                );
            } catch (Error) {
                setIsBlockedFromReserving(false);
                setIsReserveButtonEnabled(true);
                setMissedReservationMessageBarMessage('');
            }
        }

        checkIfBlockedFromReservingAhead();
    }, [authContext, selectedFacility, setMissedReservationMessageBarMessage, userContext]);

    const [timeAvailabiltyChanges, setTimeAvailabiltyChanges] = useState<
        | {
              changes: IReservationPromiseInstruction[];
              timeAvailability: ITimeAvailability;
          }
        | undefined
    >(undefined);

    useEffect(() => {
        if (timeAvailabiltyChanges) {
            async function changes(timeAvailabilityChanges: {
                changes: IReservationPromiseInstruction[];
                timeAvailability: ITimeAvailability;
            }): Promise<void> {
                const iSeatAvailabilityCalendarResponses = await timeAvailabilityChanges
                    .timeAvailability.calendarResponses;
                for (const iSeatAvailabilityCalendarResponse of iSeatAvailabilityCalendarResponses) {
                    for (const reservationInstruction of timeAvailabilityChanges.changes) {
                        switch (reservationInstruction.type) {
                            case 'cancel':
                                applyCancelInstruction(
                                    reservationInstruction,
                                    iSeatAvailabilityCalendarResponse,
                                );
                                break;
                            case 'make':
                                applyMakeInstruction(
                                    reservationInstruction,
                                    iSeatAvailabilityCalendarResponse,
                                );
                                break;
                            case 'reschedule':
                                applyRescheduleInstruction(
                                    reservationInstruction,
                                    iSeatAvailabilityCalendarResponse,
                                );
                                break;
                        }
                    }
                }

                const newTimeAvailability = {
                    facilityId: timeAvailabilityChanges.timeAvailability.facilityId,
                    startTime: timeAvailabilityChanges.timeAvailability.startTime,
                    endTime: timeAvailabilityChanges.timeAvailability.endTime,
                    calendarResponses: new Promise((resolve) => {
                        resolve(iSeatAvailabilityCalendarResponses);
                    }),
                } as ITimeAvailability;
                setTimeAvailability(newTimeAvailability);
                setTimeAvailabiltyChanges(undefined);
            }

            changes(timeAvailabiltyChanges);
        }
    }, [timeAvailabiltyChanges]);

    function updateFacilitySeatStatuses(changes: IReservationPromiseInstruction[]): void {
        if (changes && timeAvailability && changes.length > 0) {
            setTimeAvailabiltyChanges({ changes: changes, timeAvailability: timeAvailability });
        } else {
            setTimeAvailabiltyChanges(undefined);
        }
    }

    return (
        <>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <Tabs onChange={onTabChange} selectedKey={getDefaultTabKey()}>
                <TabbedContent tabHeader={FacilitiesMenuNavigationText.NewReservation}>
                    <SidebarAndContents>
                        <SidebarPane>
                            {facilitySearch}
                            {reservationView === ReservationView.Map && (
                                <>{seatQualifiersElement()}</>
                            )}
                        </SidebarPane>
                        <ContentPane>
                            <div style={contentPaneStyle()}>
                                {facilityReservationView()}
                                {missedReservationMessage && (
                                    <div>{MissedReservationMessageBar()}</div>
                                )}
                                {reservationView === ReservationView.Map && !shouldUseSvgMap && (
                                    <FacilitiesAzureIndoorMap
                                        theme={'light'}
                                        facility={selectedFacility}
                                        updateFacilitySeatStatuses={updateFacilitySeatStatuses}
                                        timeAvailability={timeAvailability}
                                        facilitySeats={selectedFacilitySeats}
                                        onFeaturesLoaded={(): void => setFeaturesLoaded(true)}
                                        featuresLoaded={hasFeaturesLoaded}
                                        isViewOnly={isViewOnly()}
                                        visible={true}>
                                        <FacilitiesLegend />
                                    </FacilitiesAzureIndoorMap>
                                )}
                                {reservationView === ReservationView.Map && shouldUseSvgMap && (
                                    <FacilitiesSvgIndoorMap
                                        facility={selectedFacility}
                                        updateFacilitySeatStatuses={updateFacilitySeatStatuses}
                                        timeAvailability={timeAvailability}
                                        facilitySeats={selectedFacilitySeats}
                                        isViewOnly={isViewOnly()}
                                    />
                                )}
                                {reservationView === ReservationView.Calendar && (
                                    <FacilitiesReservationCalendar
                                        selectedDate={selectedDate}
                                        selectedFacility={selectedFacility}
                                        facilitySeats={selectedFacilitySeats}
                                        setIsReserveClicked={setIsReserveButtonClicked}
                                        isReserveClicked={isReserveButtonClicked}
                                        setIsReserveButtonEnabled={setIsReserveButtonEnabled}
                                        isUserBlockedFromReserving={isBlockedFromReserving}
                                    />
                                )}
                            </div>
                        </ContentPane>
                    </SidebarAndContents>
                </TabbedContent>
                <TabbedContent tabHeader={FacilitiesMenuNavigationText.MyReservations}>
                    <FacilitiesUserReservationTableView facilities={facilities} />
                </TabbedContent>
            </Tabs>
        </>
    );
}
