import React, { FormEvent, useState, useContext, useEffect } from 'react';
import SidebarAndContents, {
    SidebarPane,
    ContentPane,
} from 'components/common/sidebar-and-contents';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { Role } from 'configs/roles';
import {
    userManageAttributesBreadcrumbs,
    userManageEligibilitiesBreadcrumbs,
    userInfoBreadcrumbs,
    userAssignmentUrl,
    userSearchBreadcrumbs,
    userAssignmentsBreadcrumbs,
} from 'components/user-assignments/breadcrumbs';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { employeeCardPadding } from 'components/common/employee/employee-card';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { globalFilterSeparatorStyles } from 'assets/styles/global-styles';
import {
    IPersonaProps,
    Separator,
    ChoiceGroup,
    IChoiceGroupOption,
    MessageBar,
    MessageBarType,
    mergeStyleSets,
    Dropdown,
    IDropdownOption,
} from '@fluentui/react';
import Spacer from 'components/common/spacer';
import { IEmployee } from 'clients/employee-client';
import { Location } from 'history';
import { Redirect } from 'react-router-dom';
import { UserAssignmentsContext } from 'components/user-assignments/user-assignments-context';
import EmployeeClient from 'clients/employee-client';
import { getEmployeeFromPersona } from 'utils/internal-persona-utils';
import {
    useToggle,
    useFetch,
    useFetchSimple,
    GeneralFetchResultDataType,
    useIsMounted,
} from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import EligibilityClient, {
    IAttributeAssignment,
    IAttributeAssignmentDetail,
    IEligibilityAssignment,
    IEligibilityAssignmentDetail,
    PersonnelTypes,
} from 'clients/eligibility-client';
import Tabs, { TabbedContent } from 'components/common/tabs';
import {
    searchOptions,
    defaultSearchOption,
    UserAssignmentsTabs,
    AssignmentsPageContentType,
} from 'components/user-assignments/user-assignments-constants';
import EmployeeInfoDisplay from 'components/user-assignments/display-person-info/display-employee-info';
import DisplayVisitorInfo from 'components/user-assignments/display-person-info/display-visitor-info';
import { UserContext } from 'contexts/user-context';
import ManageAttributes from 'components/user-assignments/manage-attributes/manage-attributes';
import ManageEligibilities from 'components/user-assignments/manage-eligibilities/manage-eligibilities';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import VisitorClient, { IVisitorProfile } from 'clients/visitor-client';
import VisitorsFilters from 'components/visitors/visitors-filters';
import {
    VisitorsDataContext,
    VisitorLoadWarningMsgBar,
    VisitorLoadErrorMsgBar,
} from 'components/visitors/visitors-data-context';
import { RedirectType } from 'types/global-types';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import Equipment from 'components/user-assignments/equipment/equipment';
import { FacilityUserType } from 'utils/facilities-utils';

export interface IUserAssignmentsComponentProps {
    location: Location;
    contentType: AssignmentsPageContentType;
    visitorIdOrAlias?: string;
    showSearchBox: boolean;

    showEmployeeInfo: boolean;
    showViewOthersProfileButton: boolean;

    showVisitorInfo: boolean;
    showEditVisitorButton: boolean;

    showAttributes: boolean;
    showAddAttributeButton: boolean;
    showDeleteAttributeButton: boolean;

    showEligibilities: boolean;
    showAddEligibilityButton: boolean;
    showDeleteEligibilityButton: boolean;

    showEquipment: boolean;
}

export default function UserAssignments(props: IUserAssignmentsComponentProps): JSX.Element {
    const context = useContext(UserAssignmentsContext);
    const profileVisitor = useFeatureFlag(FeatureFlagKeys.profileVisitor);
    const userContext = useContext(UserContext);
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const visitorDataContext = useContext(VisitorsDataContext);

    const isMounted = useIsMounted();
    const tab = new RegExp('/info\\b').test(props.location.pathname)
        ? UserAssignmentsTabs.PersonnelInfo
        : new RegExp('/attributes\\b').test(props.location.pathname)
        ? UserAssignmentsTabs.ManageAttributes
        : new RegExp('/eligibilities\\b').test(props.location.pathname)
        ? UserAssignmentsTabs.ManageEligibilities
        : new RegExp('/equipment\\b').test(props.location.pathname)
        ? UserAssignmentsTabs.Equipment
        : '';

    const [employee, setEmployee] = useState<IEmployee | undefined>();
    const [shouldGetAssignment, toggleShouldGetAssignment] = useToggle(false);
    const [visitorDropdown, setVisitorDropdown] = useState<IDropdownOption[]>([]);
    const [redirect, setRedirect] = useState<RedirectType>();
    const [searchOption, setSearchOption] = useState<IChoiceGroupOption | undefined>(
        defaultSearchOption({
            // props.showVisitorInfo must be determined based on user's role
            enableVisitor: props.showVisitorInfo,
            // showEmployeeInfo must be determined based on user's roles
            enableEmployee: props.showEmployeeInfo,
        }),
    );
    const [problemLoadingEmployee, setProblemLoadingEmployee] = useState<string>();
    const [errorCreateAssignmentRecord, setErrorCreateAssignmentRecord] = useState<string>();
    const [visitorVar, setVisitorVar] = useState<GeneralFetchResultDataType<IVisitorProfile>>();
    const visitor = visitorVar?.value;
    const problemLoadingVisitor = visitorVar?.errMsg;
    const shouldFetchVisitor = visitorVar?.shouldFetch;

    const [attributeAssignment, setAttributeAssignment] = useState<IAttributeAssignment>();
    const [isAttributeAssignmentFetchAttempted, setIsAttributeAssignmentFetchAttempted] = useState<
        boolean
    >();
    const [eligibilityAssignment, setEligibilityAssignment] = useState<IEligibilityAssignment>();
    const [isEligAssignmentFetchAttempted, setIsEligAssignmentFetchAttempted] = useState<boolean>();
    const [isCreatingAssignmentRecord, setIsCreatingAssignmentRecord] = useState<boolean>();
    const canShowEquipmentTab =
        props.showEquipment &&
        userContext.isFacilitiesTokenLoaded &&
        (userContext.hasFacilitiesUserType(FacilityUserType.EquipmentOfficerRole) ||
            userContext.hasFacilitiesUserType(FacilityUserType.ManagerRole) ||
            userContext.hasFacilitiesUserType(FacilityUserType.AdminService));

    const { item: sponsor } = useFetch({
        dependencies: [authContext, visitor],
        canPerformFetch: (): boolean => !!visitor?.sponsor,
        fetchFunc: async () => {
            // The following type casts are safe because the function
            // canPerformFetch above has made sure visitor.sponsor is defined.
            if (context.employeeDict[visitor?.sponsor as string]) {
                return context.employeeDict[visitor?.sponsor as string];
            }
            const sponsor = await EmployeeClient.getEmployee(
                authContext,
                visitor?.sponsor as string,
            );
            context.addEmployee(sponsor?.id, sponsor);
            return sponsor;
        },
    });

    // The URL parameter visitorIdOrAlias, which drives the prop by the
    // same name, can show either an employee, or a visitor - never both.
    // Therefore, at most one of the following will be true if an employee
    // or visitor data has already been fetched from the server.
    //
    // The URL path dictates whether the page is an employee page
    // or a visitor page. If it's neither, it might be the "search page".
    // Although there's no "search page" per se. Rather, it's just the
    // URL that says "/profile/user/search", which lands on this page
    // that you're reading now, and for that URL it doesn't show any
    // employee or visitor. It shows just a search box. When an employee
    // or visitor is loaded, the page still shows the search box, but it
    // also shows the loaded employee or visitor.
    //
    // Therefore, when either of the following is true, it implies that
    // not only an employee or a visitor has been loaded, but also his/her
    // information must be shown on the page. When neither is set, on the
    // other hand, it must be either the search page URL, or that there
    // was no visitor or employee with the specified Id.
    const isVisitorPage = !!visitor && props.visitorIdOrAlias === visitor?.id;
    const isEmployeePage = !!employee && employee.alias === props.visitorIdOrAlias;

    const thePerson = !!isVisitorPage ? visitor : !!isEmployeePage ? employee : undefined;

    const personnelType = !!isVisitorPage
        ? PersonnelTypes.VISITOR
        : !!isEmployeePage
        ? PersonnelTypes.EMPLOYEE
        : undefined;

    const { isFetching: isFetchingAssignmentAttrs } = useFetch({
        dependencies: [authContext, thePerson?.id, shouldGetAssignment],
        canPerformFetch: (): boolean => !!thePerson?.id,
        fetchFunc: async () => {
            // The following type cast is safe because the function canPerformFetch has made sure it's not undefined.
            return EligibilityClient.getAssignmentAttributes(authContext, thePerson?.id as string);
        },
        onSuccess: (item): void => {
            if (item) {
                setAttributeAssignment(item);
            }
        },
        onFinally: (): void => {
            setIsAttributeAssignmentFetchAttempted(true);
        },
    });

    const { isFetching: isFetchingAssignmentEligibilities } = useFetch({
        dependencies: [authContext, thePerson?.id, shouldGetAssignment],
        canPerformFetch: (): boolean => !!thePerson?.id,
        fetchFunc: async () => {
            // The following type cast is safe because the function canPerformFetch has made sure it's not undefined.
            return EligibilityClient.getAssignmentEligibilities(
                authContext,
                thePerson?.id as string,
            );
        },
        onSuccess: (item): void => {
            if (item) {
                setEligibilityAssignment(item);
            }
        },
        onFinally: (): void => {
            setIsEligAssignmentFetchAttempted(true);
        },
    });

    const {
        item: employeeHierarchy,
        isFetching: isFetchingEmployeeHierarchy,
        problemFetching: problemFetchingEmployeeHierarchy,
    } = useFetch({
        dependencies: [authContext, employee],
        canPerformFetch: (): boolean => !!employee,
        fetchFunc: async () => {
            // The following type cast is safe because the function canPerformFetch has made sure it's not undefined.
            return EmployeeClient.getEmployeeHierarchy(authContext, employee?.id as string);
        },
    });

    useFetchSimple<IVisitorProfile>({
        dependencies: [!!props.visitorIdOrAlias, props.visitorIdOrAlias, shouldFetchVisitor],
        canPerformFetch:
            !!shouldFetchVisitor &&
            !!props.visitorIdOrAlias &&
            visitor?.id !== props.visitorIdOrAlias,
        fetchFunc: (): Promise<IVisitorProfile> => {
            setVisitorVar((currentValue) => ({
                ...currentValue,
                isFetching: true,
                shouldFetch: false,
            }));
            // The following typecast is safe becauce the condition "canPerformFetch"
            // has made sure props.visitorIdOrAlias is truthy.
            return VisitorClient.getVisitor(authContext, props.visitorIdOrAlias as string);
        },
        onSuccess: (visitor: IVisitorProfile): void => {
            if (isMounted()) {
                setVisitorVar({ value: visitor });
            }
        },
        onError: (e) => {
            if (isMounted()) {
                if (typeof e === 'string') {
                    setVisitorVar({ errMsg: e });
                } else {
                    setVisitorVar({
                        errMsg: 'There was a problem loading the visitor information',
                    });
                }
            }
        },
        onFinally: doNothing,
    });

    const isAssignmentFetchAttempted =
        isAttributeAssignmentFetchAttempted && isEligAssignmentFetchAttempted;

    const hasAssignmentRecord = !!attributeAssignment && !!eligibilityAssignment;

    const createAssignmentRecord = async (): Promise<void> => {
        try {
            setIsCreatingAssignmentRecord(true);
            await EligibilityClient.createPersonnelAssignment(authContext, {
                // The following type casts are safe because if either of the
                // following is undefined, the action button will be disabled.
                personnelId: thePerson?.id as string,
                personnelType: personnelType as PersonnelTypes,
            });
            toggleShouldGetAssignment();
        } catch (e) {
            setErrorCreateAssignmentRecord(
                'Unable to retrieve or create assignment record for this person',
            );
        } finally {
            setIsCreatingAssignmentRecord(false);
        }
    };

    useEffect(() => {
        if (
            !!thePerson?.id &&
            !hasAssignmentRecord &&
            isAssignmentFetchAttempted &&
            authContext.isInRole(Role.PersonnelAssignmentWrite)
        ) {
            createAssignmentRecord();
        }
    }, [userContext, authContext, isAssignmentFetchAttempted, thePerson?.id, hasAssignmentRecord]);

    useEffect(() => {
        if (props.visitorIdOrAlias?.toLocaleUpperCase() !== props.visitorIdOrAlias) {
            const re = new RegExp(`\\b${props.visitorIdOrAlias}\\b`);
            const newPath = props.location.pathname.replace(re, (match): string =>
                match.toLocaleUpperCase(),
            );
            setRedirect({ to: newPath, push: false });
        }
    }, [props.location.pathname, props.visitorIdOrAlias]);

    /**
     * Determine breadcrumbs
     */

    useEffect(() => {
        const { SearchBox, MyInfo, EmployeeOrVisitor } = AssignmentsPageContentType;
        const { PersonnelInfo, ManageAttributes, ManageEligibilities } = UserAssignmentsTabs;
        switch (props.contentType) {
            case SearchBox:
                breadCrumbContext.setBreadCrumbs([...userSearchBreadcrumbs()]);
                break;
            case MyInfo:
            case EmployeeOrVisitor:
                if (!!thePerson) {
                    switch (tab) {
                        case PersonnelInfo:
                            // This clause covers the path /profile/user/:visitorIdOrAlias/info
                            breadCrumbContext.setBreadCrumbs([...userInfoBreadcrumbs(thePerson)]);
                            break;
                        case ManageAttributes:
                            // This clause covers the path profile/user/:visitorIdOrAlias/attributes
                            breadCrumbContext.setBreadCrumbs([
                                ...userManageAttributesBreadcrumbs(thePerson),
                            ]);
                            break;
                        case ManageEligibilities:
                            // This clause covers the path profile/user/:visitorIdOrAlias/attributes
                            breadCrumbContext.setBreadCrumbs([
                                ...userManageEligibilitiesBreadcrumbs(thePerson),
                            ]);
                            break;
                        default:
                            breadCrumbContext.setBreadCrumbs([...userAssignmentsBreadcrumbs]);
                    }
                } else {
                    breadCrumbContext.setBreadCrumbs([...userAssignmentsBreadcrumbs]);
                }
        }
        return;
    }, [props.contentType, thePerson, tab]);

    /*
     * END Determine breadcrumbs
     **/

    const onTabChange = (itemKey?: string): void => {
        switch (itemKey) {
            case UserAssignmentsTabs.PersonnelInfo:
            case UserAssignmentsTabs.ManageAttributes:
            case UserAssignmentsTabs.ManageEligibilities:
            case UserAssignmentsTabs.Equipment:
                setRedirect({
                    to: userAssignmentUrl(
                        props.contentType,
                        props.visitorIdOrAlias?.toLocaleUpperCase(),
                        itemKey,
                    ),
                    push: true,
                });
                break;
            default:
                break;
        }
    };

    const onEmployeeSelect = (info?: IPersonaProps): void => {
        const employeeVar = getEmployeeFromPersona(info);
        if (!employeeVar || !employeeVar.id || !employeeVar.alias) {
            return;
        }
        setEmployee(employeeVar);
        context.addEmployee(employeeVar.id, employeeVar);
        setRedirect({
            to: userAssignmentUrl(
                AssignmentsPageContentType.EmployeeOrVisitor,
                employeeVar.alias.toLocaleUpperCase(),
            ),
            push: true,
        });
    };

    const fetchEmployeeInfo = async (): Promise<void> => {
        if (!props.visitorIdOrAlias) return;

        setProblemLoadingEmployee('');

        if (context.employeeDict[props.visitorIdOrAlias]) {
            setEmployee(context.employeeDict[props.visitorIdOrAlias]);
            return;
        }

        try {
            const employeeVar = await EmployeeClient.getEmployee(
                authContext,
                props.visitorIdOrAlias,
            );
            if (isMounted()) {
                context.addEmployee(props.visitorIdOrAlias, employeeVar);
                setEmployee(employeeVar);
            }
        } catch (e) {
            if (isMounted()) {
                setVisitorVar((currentValue) => ({
                    ...currentValue,
                    shouldFetch: true,
                }));
                if (typeof e === 'string') {
                    setProblemLoadingEmployee(e);
                } else {
                    setProblemLoadingEmployee(
                        'There was a problem loading the employee information',
                    );
                }
            }
            console.error(e);
        }
    };

    useEffect(() => {
        fetchEmployeeInfo();
    }, [props.visitorIdOrAlias]);

    function onSearchOptionChange(
        ev?: FormEvent<HTMLInputElement | HTMLElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (option) setSearchOption(option);
    }

    const onSearchClick = (): void => {
        visitorDataContext.fetchVisitors(isMounted, { replaceTable: true });
    };

    useEffect(() => {
        if (visitorDataContext.continuationToken) {
            visitorDataContext.fetchVisitors(isMounted);
        }
    }, [visitorDataContext.continuationToken]);

    const dropdownText = (): string => {
        if (visitorDropdown.length > 0) {
            return 'Select a visitor';
        } else {
            return (
                (visitorDataContext.isLoading ? 'Searching ...' : undefined) ||
                'Search for visitors'
            );
        }
    };

    const dropdownSelectedKey = (): string | undefined => {
        return visitorDropdown.length === 1 ? (visitorDropdown[0].key as string) : undefined;
    };

    useEffect(() => {
        setVisitorDropdown(
            visitorDataContext.visitors.map((visitor) => {
                const { id, firstName, lastName, middleName } = visitor;
                return {
                    key: visitor.id,
                    text: `${firstName} ${middleName} ${lastName} (${id})`,
                };
            }),
        );
    }, [visitorDataContext.visitors]);

    useEffect(() => {
        // If the search returned only one visitor, go to their profile.
        if (visitorDataContext.visitors?.length === 1) {
            const visitor = visitorDataContext.visitors[0];
            setRedirect({
                to: userAssignmentUrl(AssignmentsPageContentType.EmployeeOrVisitor, visitor.id),
                push: true,
            });
        }
    }, [visitorDataContext.visitors]);

    const onVisitorSelect = (
        event: React.FormEvent<HTMLElement> | undefined,
        option?: IDropdownOption,
    ): void => {
        if (event?.type === 'click' && option?.key) {
            setRedirect({
                to: userAssignmentUrl(
                    AssignmentsPageContentType.EmployeeOrVisitor,
                    option.key as string,
                ),
                push: true,
            });
        }
    };

    const showEmployeeCard = (): JSX.Element => {
        return (
            <>
                <EmployeeBasicHoverCard
                    key={thePerson?.id ?? 'employee-card'}
                    personnelId={thePerson?.id}
                    noHoverCard={true}
                    displayActions={false}
                    employeeCardStyle={{
                        card: { minWidth: 0 },
                        cardActions: { marginTop: employeeCardPadding },
                    }}
                />
                <Spacer marginTop={15} />
            </>
        );
    };

    const showSearchBox = (): JSX.Element => {
        return (
            <>
                <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                    Search
                </Separator>
                <ChoiceGroup
                    aria-label='Search Type'
                    styles={{
                        flexContainer: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                        },
                    }}
                    selectedKey={searchOption?.key}
                    options={searchOptions({
                        // showEmployeeInfo must be determined based on user's roles
                        enableEmployee: props.showEmployeeInfo,
                        // props.showVisitorInfo must be determined based on user's role
                        enableVisitor: profileVisitor.enabled && props.showVisitorInfo,
                    })}
                    onChange={onSearchOptionChange}
                />
                <Spacer marginTop={15} />
                {searchOption?.key === PersonnelTypes.EMPLOYEE && (
                    <EmployeePickerTypeaheadSearch
                        ariaLabel='Employee Search'
                        placeHolder='Employee Name or Alias'
                        onCandidateSelected={onEmployeeSelect}
                    />
                )}
                {/* // props.showVisitorInfo must be determined based on user's role */}
                {props.showVisitorInfo && searchOption?.key === PersonnelTypes.VISITOR && (
                    <>
                        <Dropdown
                            ariaLabel='Visitor Search'
                            options={visitorDropdown}
                            disabled={visitorDropdown.length === 0}
                            onChange={onVisitorSelect}
                            placeholder={dropdownText()}
                            selectedKey={dropdownSelectedKey()}
                        />
                        <VisitorLoadWarningMsgBar />
                        <VisitorLoadErrorMsgBar />
                        <VisitorsFilters onSearchClick={onSearchClick} />
                    </>
                )}
            </>
        );
    };

    const showSidebar = (): JSX.Element => {
        return (
            <>
                {isEmployeePage && showEmployeeCard()}
                {/* showSearchBox must be determined based on user's roles */}
                {props.showSearchBox && showSearchBox()}
            </>
        );
    };

    useEffect(() => {
        if (!!redirect?.to) {
            setRedirect(undefined);
        }
    }, [redirect]);

    const updateAddAttributeAssignment = async (): Promise<void> => {
        if (thePerson?.id) {
            try {
                const newAttributes = await EligibilityClient.getAssignmentAttributes(
                    authContext,
                    thePerson.id,
                );
                setAttributeAssignment(newAttributes);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const deleteAttributeAssignment = (item: IAttributeAssignmentDetail): void => {
        if (attributeAssignment?.id) {
            const { attributeCode } = item;
            const tempAttributes = attributeAssignment?.attributes?.filter(
                (attribute: IAttributeAssignmentDetail) =>
                    attribute.attributeCode !== attributeCode,
            );
            const updatedAttributes: IAttributeAssignment = {
                ...attributeAssignment,
                attributes: tempAttributes,
            };

            setAttributeAssignment(updatedAttributes);
        }
    };

    const updateAddEligibilityAssignment = async (): Promise<void> => {
        if (thePerson?.id) {
            try {
                const newEligibilities = await EligibilityClient.getAssignmentEligibilities(
                    authContext,
                    thePerson.id,
                );
                setEligibilityAssignment(newEligibilities);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const deleteEligibilityAssignment = (item: IEligibilityAssignmentDetail): void => {
        if (eligibilityAssignment?.id) {
            const { eligibilityCode } = item;
            const tempEligibilities = eligibilityAssignment?.eligibilities?.filter(
                (eligibility: IEligibilityAssignmentDetail) =>
                    eligibility.eligibilityCode !== eligibilityCode,
            );
            const updatedEligibilities: IEligibilityAssignment = {
                ...eligibilityAssignment,
                eligibilities: tempEligibilities,
            };

            setEligibilityAssignment(updatedEligibilities);
        }
    };

    const showErrMsg = (): JSX.Element => {
        let errMsg: string | undefined;

        if (errorCreateAssignmentRecord) {
            errMsg = errorCreateAssignmentRecord;
        } else if (!!problemLoadingEmployee && !!problemLoadingVisitor) {
            errMsg = 'Problem loading employee or visitor with the given Id';
        }

        if (!errMsg) {
            return <></>;
        }

        return (
            <div className={styles.messageBox}>
                <MessageBar messageBarType={MessageBarType.error}>{errMsg}</MessageBar>
            </div>
        );
    };

    if (!!redirect?.to && redirect.to !== props.location.pathname) {
        return <Redirect push={redirect.push} to={redirect.to} />;
    } else {
        return (
            <>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <div>
                    <Tabs selectedKey={tab} onChange={onTabChange}>
                        {/* // showEmployeeInfo and props.showVisitorInfo must be determined based on user's roles */}
                        {(props.showEmployeeInfo || props.showVisitorInfo) && (
                            <TabbedContent
                                key='Information'
                                tabHeader='Information'
                                itemKey={UserAssignmentsTabs.PersonnelInfo}>
                                <SidebarAndContents>
                                    <SidebarPane>{showSidebar()}</SidebarPane>
                                    <ContentPane>
                                        {showErrMsg()}
                                        {/* // props.showVisitorInfo must be determined based on user's role */}
                                        {props.showVisitorInfo && isVisitorPage && (
                                            <>
                                                <DisplayVisitorInfo
                                                    // The following type cast is safe because the condition
                                                    // above where it checks isVisitorPage has made sure
                                                    // thePerson is a visitor profile.
                                                    visitor={thePerson as IVisitorProfile}
                                                    sponsor={sponsor}
                                                    showEditVisitorButton={
                                                        // props.showEditVisitorButton must be determined based on user's role
                                                        props.showEditVisitorButton
                                                    }
                                                />
                                                <IsLoadingIndicator
                                                    msg='Creating user assignment record'
                                                    before={<Spacer marginTop={10} />}
                                                    after={<Spacer marginTop={10} />}
                                                    isLoading={!!isCreatingAssignmentRecord}
                                                />
                                            </>
                                        )}
                                        {/* // showEmployeeInfo must be determined based on user's roles */}
                                        {props.showEmployeeInfo && isEmployeePage && (
                                            <>
                                                <EmployeeInfoDisplay
                                                    employee={employee}
                                                    employeeHierarchy={employeeHierarchy}
                                                    isFetchingEmployeeHierarchy={
                                                        isFetchingEmployeeHierarchy
                                                    }
                                                    problemFetchingEmployeeHierarchy={
                                                        problemFetchingEmployeeHierarchy
                                                    }
                                                    showViewOthersProfileButton={
                                                        // props.showViewOthersProfileButton must be determined based on user's role
                                                        props.showViewOthersProfileButton
                                                    }
                                                />
                                                <IsLoadingIndicator
                                                    msg='Creating user assignment record'
                                                    before={<Spacer marginTop={10} />}
                                                    after={<Spacer marginTop={10} />}
                                                    isLoading={!!isCreatingAssignmentRecord}
                                                />
                                            </>
                                        )}
                                    </ContentPane>
                                </SidebarAndContents>
                            </TabbedContent>
                        )}
                        {/* // props.showAttributes  must be determined based on user's role */}
                        {props.showAttributes &&
                            !!props.visitorIdOrAlias &&
                            hasAssignmentRecord &&
                            isAssignmentFetchAttempted && (
                                <TabbedContent
                                    key='Attributes'
                                    tabHeader='Attributes'
                                    itemKey={UserAssignmentsTabs.ManageAttributes}>
                                    <SidebarAndContents>
                                        <SidebarPane>{showSidebar()}</SidebarPane>
                                        <ContentPane>
                                            {showErrMsg()}
                                            {attributeAssignment && personnelType && thePerson?.id && (
                                                <ManageAttributes
                                                    updateAddAttributeAssignment={
                                                        updateAddAttributeAssignment
                                                    }
                                                    isLoading={isFetchingAssignmentAttrs}
                                                    attrAssignment={attributeAssignment}
                                                    visitorOrEmployee={thePerson}
                                                    personnelId={thePerson.id}
                                                    personnelType={personnelType}
                                                    deleteAttributeFromTable={(
                                                        attr: IAttributeAssignmentDetail,
                                                    ): void => {
                                                        deleteAttributeAssignment(attr);
                                                    }}
                                                    showAddAttributeButton={
                                                        // props.showAddAttributeButton must be determined based on user's role
                                                        props.showAddAttributeButton
                                                    }
                                                    showDeleteAttributeButton={
                                                        // props.showDeleteAttributeButton must be determined based on user's role
                                                        props.showDeleteAttributeButton
                                                    }
                                                />
                                            )}
                                        </ContentPane>
                                    </SidebarAndContents>
                                </TabbedContent>
                            )}
                        {/* // props.showEligibilities  must be determined based on user's role */}
                        {props.showEligibilities &&
                            !!props.visitorIdOrAlias &&
                            hasAssignmentRecord &&
                            isAssignmentFetchAttempted && (
                                <TabbedContent
                                    key='Eligibilities'
                                    tabHeader='Eligibilities'
                                    itemKey={UserAssignmentsTabs.ManageEligibilities}>
                                    <SidebarAndContents>
                                        <SidebarPane>{showSidebar()}</SidebarPane>
                                        <ContentPane>
                                            {showErrMsg()}
                                            {eligibilityAssignment && thePerson?.id && (
                                                <ManageEligibilities
                                                    isLoading={isFetchingAssignmentEligibilities}
                                                    eligibilityAssignment={eligibilityAssignment}
                                                    visitorOrEmployee={thePerson}
                                                    personnelId={thePerson.id}
                                                    updateAddEligibilityAssignment={
                                                        updateAddEligibilityAssignment
                                                    }
                                                    deleteEligibilityFromTable={(
                                                        attr: IEligibilityAssignmentDetail,
                                                    ): void => {
                                                        deleteEligibilityAssignment(attr);
                                                    }}
                                                    showAddEligibilityButton={
                                                        // props.showAddEligibilityButton must be determined based on user's role
                                                        props.showAddEligibilityButton
                                                    }
                                                    showDeleteEligibilityButton={
                                                        // props.showDeleteEligibilityButton must be determined based on user's role
                                                        props.showDeleteEligibilityButton
                                                    }
                                                />
                                            )}
                                        </ContentPane>
                                    </SidebarAndContents>
                                </TabbedContent>
                            )}
                        {canShowEquipmentTab && (
                            <TabbedContent
                                key='Equipment'
                                tabHeader='Equipment'
                                itemKey={UserAssignmentsTabs.Equipment}>
                                <SidebarAndContents>
                                    <SidebarPane>{showSidebar()}</SidebarPane>
                                    <ContentPane>
                                        <Equipment
                                            employee={thePerson as IEmployee}
                                            isAdminPage={props.showSearchBox}
                                        />
                                    </ContentPane>
                                </SidebarAndContents>
                            </TabbedContent>
                        )}
                    </Tabs>
                </div>
            </>
        );
    }
}

const styles = mergeStyleSets({
    messageBox: {
        width: 360,
        padding: 5,
    },
});
