import config from 'environments/environment';
import atlas, {
    AuthenticationOptions,
    AuthenticationType,
    CameraBoundsOptions,
    CameraOptions,
    control,
    ControlPosition,
    ControlStyle,
    data,
    layer,
    Popup,
    Shape,
    source,
} from 'azure-maps-control';
import FacilitiesClient, {
    IFacilityRecord,
    ISeatRecord,
    ReservationState,
    SeatStatusArray,
    SeatStatuses,
} from 'clients/facilities-client';
import { ReactNode } from 'react';
import { IAuthContext } from 'contexts/auth-context';
import { IUserContext } from 'contexts/user-context';
import { indoor } from 'azure-maps-indoor';
import { Map as AzureMap } from 'azure-maps-control'; // Referencing 'atlas' import directly seems to sometimes not work (ie. atlas.source.DateSource)
import { Dictionary } from 'assets/constants/global-constants';
import { ISeatSlot } from 'components/facilities/common/facilities-timeslot-utils';

const { facilitiesServiceConfig } = config;
export const defaultMapContainerId = 'map-container-id';
export const seatColorProperty = 'seatColor';
export const seatColorPolygonLayerId = 'seat-color-polygon-layer-id';
export const seatColorLineLayerId = 'seat-color-line-layer-id';

export async function createAzureMap(
    authContext: IAuthContext,
    userContext: IUserContext,
    facility?: IFacilityRecord,
    mapContainerId?: string,
): Promise<AzureMap> {
    const defaultCamera = getDefaultCamera(facility);
    return new AzureMap(mapContainerId ?? defaultMapContainerId, {
        style: 'blank',
        showFeedbackLink: false,
        showLogo: false,
        view: 'Auto',
        authOptions: await getAuthOptions(authContext, userContext),
        zoom: defaultCamera.zoom,
        bounds: defaultCamera.bounds,
        center: defaultCamera.center,
    });
}

export async function getAuthOptions(
    authContext: IAuthContext,
    userContext: IUserContext,
): Promise<AuthenticationOptions> {
    return {
        authType: AuthenticationType.anonymous,
        clientId: FacilitiesClient.deserializeFacilitiesToken(
            await userContext.refreshFacilitiesToken(),
        )?.azureMapClientId,
        getToken: async function (resolve: (value?: string) => void) {
            try {
                const tokenServiceUrl = `${facilitiesServiceConfig.baseUrl}${facilitiesServiceConfig.azureMapsAadTokenEndpoint}`;
                const facilitiesHttpOptions = await FacilitiesClient.getFacilitiesHttpOptionsWithFacilitiesToken(
                    authContext,
                    userContext,
                );
                fetch(tokenServiceUrl, facilitiesHttpOptions)
                    .then((r) => r.text())
                    .then((token) => resolve(token));
            } catch (e) {
                if (authContext.isKioskRenderMode()) {
                    throw e;
                } else if (e instanceof Error) {
                    console.log(e.message);
                } else {
                    console.log(e);
                }
            }
        },
    };
}

export function getDefaultCamera(facility?: IFacilityRecord): CameraOptions | CameraBoundsOptions {
    return {
        zoom: 19,
        bounds: facility?.azureIndoorMapDataInfo?.tilesetInfo?.boundingBox ?? [
            -180.0,
            -90.0,
            180.0,
            90.0,
        ],
        center: [0, 0],
    };
}

export function changeMap(
    azureMap?: AzureMap,
    indoorManager?: indoor.IndoorManager,
    facility?: IFacilityRecord,
    theme?: 'auto' | 'dark' | 'light',
): void {
    // Change tileset id
    indoorManager?.setOptions(getIndoorManagerOptions(facility, theme));

    // Reset the camera
    azureMap?.setCamera(getDefaultCamera(facility));
}

export function getIndoorManagerOptions(
    facility?: IFacilityRecord,
    theme?: 'auto' | 'dark' | 'light',
    geography?: 'us' | 'eu',
): indoor.IndoorManagerOptions {
    return {
        geography: geography ?? 'us',
        theme: theme,
        tilesetId: facility?.azureIndoorMapDataInfo?.tilesetInfo?.id ?? undefined,
    };
}

export function getFeatureCenterPosition(
    feature: atlas.data.Feature<atlas.data.Geometry, any>,
): data.Position {
    let boundingBox: data.BoundingBox;
    let coordinates: any;
    const positions: data.Position[] = [];

    switch (feature.geometry.type) {
        case 'Point':
            boundingBox = data.BoundingBox.fromPositions([
                (feature.geometry as data.Point).coordinates,
            ]);
            break;
        case 'MultiPoint':
        case 'LineString':
            boundingBox = data.BoundingBox.fromPositions(
                (feature.geometry as data.MultiPoint | data.LineString).coordinates,
            );
            break;
        case 'MultiLineString':
        case 'Polygon':
            coordinates = (feature.geometry as data.MultiLineString | data.Polygon).coordinates;

            for (let i = 0; i < coordinates.length; i++) {
                for (let j = 0; j < coordinates[0].length; j++) {
                    positions.push(coordinates[i][j]);
                }
            }

            boundingBox = data.BoundingBox.fromPositions(positions);
            break;
        case 'MultiPolygon':
            coordinates = (feature.geometry as data.MultiPolygon).coordinates;

            for (let i = 0; i < coordinates.length; i++) {
                for (let j = 0; j < coordinates[0].length; j++) {
                    for (let k = 0; k < coordinates[0][0].length; k++) {
                        positions.push(coordinates[i][j][k]);
                    }
                }
            }

            boundingBox = data.BoundingBox.fromPositions(positions);
            break;
        default:
            boundingBox = new data.BoundingBox([]);
            console.log(`Unexpected geometry type ${feature.geometry.type} was provided`);
    }

    return data.BoundingBox.getCenter(boundingBox);
}

export function getSeatFeatureClicked(
    shapes: Array<atlas.data.Feature<atlas.data.Geometry, any> | atlas.Shape>,
    seatDict: Dictionary<ISeatRecord>,
): atlas.data.Feature<atlas.data.Geometry, any> | undefined {
    const seatFeature = shapes.find(
        (x) =>
            (x as any).type?.toLowerCase() === 'feature' &&
            (x as any).properties?.name &&
            seatDict[(x as any).properties?.name.toLowerCase()],
    ) as atlas.data.Feature<atlas.data.Geometry, any> | undefined;
    return seatFeature;
}

// create shapes with the seat status colors to place on top of the seat features
export function createSeatStatusColorShapes(
    azureMap?: AzureMap,
    dataSource?: source.DataSource,
    seats?: ISeatRecord[],
    seatStatuses?: ISeatSlot[],
): void {
    if (
        azureMap &&
        azureMap.layers.getRenderedShapes().length > 0 &&
        seats &&
        seatStatuses &&
        dataSource
    ) {
        const seatDict = getFacilitySeatDict(seats);
        const seatStatusDict = getFacilitySeatStatusDict(seatStatuses);

        azureMap.popups.clear();

        const seatPolygonFeatures = azureMap.layers
            .getRenderedShapes()
            .filter(
                (x) =>
                    (x as any).type?.toLowerCase() === 'feature' &&
                    (x as any).properties?.name &&
                    seatDict[(x as any).properties?.name.toLowerCase()] &&
                    isPolygon(x as atlas.data.Feature<atlas.data.Geometry, any>),
            )
            .map((x) => x as atlas.data.Feature<atlas.data.Geometry, any>);

        const newSeatColorShapes: Shape[] = [];

        seatPolygonFeatures.forEach((x) => {
            const seat = seatDict[x.properties?.name.toLowerCase()];
            if (seat && seatStatusDict[seat.id]) {
                const seatStatus = SeatStatusArray.find(
                    (x) => seatStatusDict[seat.id].status.toLowerCase() === x.value.toLowerCase(),
                );
                if (seatStatus) {
                    const shape = new Shape(
                        new data.Feature(
                            new data.Polygon(x.geometry.coordinates as any, x.bbox),
                            undefined,
                            seat.id,
                        ),
                    );
                    shape.addProperty(seatColorProperty, seatStatus.index);
                    newSeatColorShapes.push(shape);

                    seatStatus.value === SeatStatuses.MySeat.value &&
                        createPopup(
                            x,
                            `<div style="padding:5px;">${getMySeatMessage(
                                seatStatusDict[seat.id],
                            )}</div>`,
                        ).open(azureMap);
                }
            }
        });

        dataSource.setShapes(newSeatColorShapes);
    }
}

function isPolygon(feature: data.Feature<data.Geometry, any>): boolean {
    return (
        feature.geometry.type.toLowerCase() === 'polygon' &&
        (feature.geometry.coordinates as data.Position[][][])[0].length >= 3
    );
}

function getMySeatMessage(mySeatStatus: ISeatSlot): string {
    switch (mySeatStatus.status) {
        case ReservationState.CheckedIn:
            return "I'm checked in to my seat here!";
        case ReservationState.Preclaimed:
            return 'Please cancel or change from your intermediate reservation for this seat!';
        case ReservationState.Confirmed:
        default:
            return 'My seat is here!';
    }
}

export function createPopup(
    seatFeature?: atlas.data.Feature<atlas.data.Geometry, any>,
    content?: string,
): atlas.Popup {
    const popup = new Popup({ closeButton: false });
    if (seatFeature) {
        popup.setOptions({
            content: content,
            position: getFeatureCenterPosition(seatFeature),
        });
    }
    return popup;
}

export function addZoomControl(azureMap: AzureMap): void {
    azureMap.controls.add(
        new control.ZoomControl({
            zoomDelta: 1,
            style: ControlStyle.auto,
        }),
        {
            position: ControlPosition.TopRight,
        },
    );
}

export function addPolygonLayer(
    azureMap: AzureMap,
    dataSrc: source.DataSource,
): layer.PolygonLayer {
    const polygonLayer = new layer.PolygonLayer(dataSrc, seatColorPolygonLayerId, {
        fillOpacity: 0.1,
        fillColor: [
            'step',
            ['get', seatColorProperty],
            '#393939', // Gray40 (default color)
            SeatStatuses.Available.index,
            SeatStatuses.Available.color,
            SeatStatuses.AvailableForICM.index,
            SeatStatuses.AvailableForICM.color,
            SeatStatuses.Unavailable.index,
            SeatStatuses.Unavailable.color,
            SeatStatuses.OutOfOrder.index,
            SeatStatuses.OutOfOrder.color,
            SeatStatuses.MySeat.index,
            SeatStatuses.MySeat.color,
        ],
        filter: ['has', seatColorProperty],
    });
    azureMap.layers.add([polygonLayer]);

    return polygonLayer;
}

export function addLineLayer(azureMap: AzureMap, dataSrc: source.DataSource): void {
    azureMap.layers.add([
        new layer.LineLayer(dataSrc, seatColorLineLayerId, {
            strokeWidth: 4,
            strokeOpacity: 1,
            strokeColor: [
                'step',
                ['get', seatColorProperty],
                '#393939', // Gray40 (default color)
                SeatStatuses.Available.index,
                SeatStatuses.Available.color,
                SeatStatuses.AvailableForICM.index,
                SeatStatuses.AvailableForICM.color,
                SeatStatuses.Unavailable.index,
                SeatStatuses.Unavailable.color,
                SeatStatuses.OutOfOrder.index,
                SeatStatuses.OutOfOrder.color,
                SeatStatuses.MySeat.index,
                SeatStatuses.MySeat.color,
            ],
            filter: ['has', seatColorProperty],
        }),
    ]);
}

export function getFacilitySeatDict(seats?: ISeatRecord[]): Dictionary<ISeatRecord> {
    if (!seats) {
        return {};
    }

    const newFacilitySeatDict: Dictionary<ISeatRecord> = {};

    // we map seat record's unit id to seat feature's unit name
    for (let i = 0; i < seats.length; i++) {
        const unitId = seats[i].unitId;
        if (unitId) {
            newFacilitySeatDict[unitId.toLowerCase()] = seats[i];
        }
    }

    return newFacilitySeatDict;
}

export function getFacilitySeatStatusDict(seatStatuses?: ISeatSlot[]): Dictionary<ISeatSlot> {
    if (!seatStatuses) {
        return {};
    }

    const newFacilitySeatStatusDict: Dictionary<ISeatSlot> = {};

    seatStatuses.forEach((x) => {
        newFacilitySeatStatusDict[x.seatId] = x;
    });

    return newFacilitySeatStatusDict;
}

export interface FacilitiesAzureIndoorMapBaseProps {
    theme?: 'dark' | 'light';
    facility?: IFacilityRecord;
    facilitySeats?: ISeatRecord[];
    children?: ReactNode;
    onFeaturesLoaded?: () => void; // Gets triggered when all features from an indoor map tileset have been loaded
    featuresLoaded?: boolean;
    isViewOnly?: boolean;
}

export enum AzureMapThemeColor {
    dark = '#313841',
    light = '#F5F5F5',
}
