import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import EmployeeClient, { IEmployee } from 'clients/employee-client';
import FacilitiesClient, {
    areTimeSlotItemsEqual,
    EarlyCheckinGracePeriodInMinutes,
    EquipmentState,
    getAvailableSeatStatus,
    IFacilitiesTokenResult,
    IFacilityRecord,
    IFacilityTimeslotItem,
    ILogBookRecord,
    IReservationRecord,
    ISeatAvailabilityCalendarResponse,
    ISeatRecord,
    ISeatStatus,
    ISeatsTimeslotStatusesForDate,
    LateCheckoutGracePeriodInMinutes,
    ReservationState,
    SmartCardArray,
    updateSeatsTimeslotStatusesForDates,
    IFacilityUserFeedbackRequest,
    IEquipmentRecord,
} from 'clients/facilities-client';
import { FacilityUserType } from 'utils/facilities-utils';
import CheckAccess from 'components/common/check-access';
import { AuthContext } from 'contexts/auth-context';
import { ServiceProviderType, UserContext } from 'contexts/user-context';
import {
    Icon,
    Rating,
    DefaultButton,
    mergeStyleSets,
    MessageBarType,
    PrimaryButton,
    Stack,
    Panel,
    PanelType,
} from '@fluentui/react';
import { useFetchSimple, useIsMounted, useToggle } from 'utils/misc-hooks';
import { doNothing, useQuery } from 'utils/misc-utils';
import Spacer from 'components/common/spacer';
import msLogo from 'assets/img/Microsoft_logo.svg';
import smartCardChip from 'assets/img/smart_card_chip.svg';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import facilitiesKioskPageTableColumns, {
    FacilitiesKioskPageTableColumnKeys,
} from 'components/facilities/facilities-kiosk/facilities-kiosk-page-table-columns';
import { numCmp, useSortColumnHandler } from 'utils/sort-utils';
import { Table } from 'components/common/table';
import { useParams } from 'react-router-dom';
import { IPagedResults } from 'clients/http-options';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import moment from 'moment';
import FacilitiesAzureIndoorMap from 'components/facilities/facilities-azure-indoor-map/facilities-azure-indoor-map';
import FacilitiesLegend from 'components/facilities/facilities-legend';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import useMessageBar from 'components/common/use-message-bar';
import { TimeFormats, minutesToMilliseconds } from 'utils/time-utils';
import BoldFont from 'components/common/misc/bold-font';
import {
    checkboxBlue,
    disabledButtonColor,
    disabledButtonTextColor,
    globalStyles,
} from 'assets/styles/global-styles';
import { toTitleCase } from 'utils/string-utils';
import {
    fullTimeEmployeeCardColor,
    vendorEmployeeCardColor,
} from 'components/common/employee/employee-utils';
import { PhotoSize } from 'clients/graph-client';
import { ITimeAvailability } from 'components/facilities/facilities-page';
import {
    IReservationPromiseInstruction,
    applyCancelInstruction,
    applyMakeInstruction,
    applyRescheduleInstruction,
} from 'components/facilities/facilities-reservations/modals/facilities-reservation-instruction-utils';
import FacilitiesSvgIndoorMap from 'components/facilities/facilities-svg-map/facilities-svg-indoor-map';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import FacilitiesKioskPanel from 'components/facilities/facilities-kiosk/facilitis-kiosk-panel';

export interface ISeatsTableRow {
    timeslotItem: IFacilityTimeslotItem;
    seat?: ISeatRecord;
    reservation?: IReservationRecord;
}

export enum KioskMessaging {
    CheckedIn = 'checked in',
    CheckedOut = 'checked out',
    Created = 'created',
    Deleted = 'deleted',
    Arriving = 'arriving',
    Leaving = 'leaving',
}

enum IKioskView {
    Main,
    Map,
    PostSubmit,
}

const hoursAhead = 12;
const forcedLogoutSeconds = 3;
const postSubmitMessageMarginStyle = { margin: '0px 0 0 43px' };
const postSubmitSeatNameMarginStyle = { margin: '-40px 0 0 63px' };

export default function FacilitiesKioskPage(): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const isMounted = useIsMounted();
    const { facilityId } = useParams<{ facilityId: string }>();
    const query = useQuery();

    const [facility, setFacility] = useState<IFacilityRecord>();
    const [employee, setEmployee] = useState<IEmployee | undefined>();
    const [employeeImage, setEmployeeImage] = useState<string>();
    const [seats, setSeats] = useState<ISeatRecord[]>();
    const [seatsContinuationToken, setSeatsContinuationToken] = useState<string>();
    const [seatsTimeslotStatuses, setSeatsTimeslotStatuses] = useState<
        ISeatsTimeslotStatusesForDate[]
    >([]);
    const [seatStatusContinuationToken, setSeatStatusContinuationToken] = useState<string>();
    const [myReservations, setMyReservations] = useState<IReservationRecord[]>();
    const [reservationNow, setReservationNow] = useState<IReservationRecord>();
    const [equipment, setEquipment] = useState<IEquipmentRecord[]>();
    const [selectedEquipment, setSelectedEquipment] = useState<IEquipmentRecord[]>();
    const [isInitialArriving, setInitialArriving] = useState<boolean>(); // compare to isArriving to indicate if the user changed their initial state as that means we do nothing on submit for log book records
    const [isArriving, setArriving] = useState<boolean>();
    const [ISeatsTableRows, setISeatsTableRows] = useState<ISeatsTableRow[]>([]);
    const [hasFeaturesLoaded, setFeaturesLoaded] = useState<boolean>(false);
    const [facilitiesToken, setFacilitiesToken] = useState<IFacilitiesTokenResult>();

    const [iTimeAvailability, setITimeAvailability] = useState<ITimeAvailability>();
    const [iSeatAvailabilityCalendarResponses, setISeatAvailabilityCalendarResponses] = useState<
        ISeatAvailabilityCalendarResponse[]
    >([]);

    const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
    const [hasMapLoaded, setMapLoaded] = useState<boolean>(false);

    const [alertMessageType, setAlertMessageType] = useState<MessageBarType>(
        MessageBarType.success,
    );
    const [view, setView] = useState<IKioskView>(IKioskView.Main);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    const [showIndoorMapView, setShowIndoorMapView] = useState<boolean>(false);
    const [showPostLoadedIndoorMapDivClass, setPostLoadedIndoorMapDivClass] = useState<boolean>(
        false,
    );

    const badgeTopStyle = useMemo(() => {
        return getBadgeTopStyle();
    }, [employee]);

    const checkMarkStyle = useMemo(() => {
        return getCheckMarkStyle();
    }, [isSubmitted]);

    const nowDate = new Date();
    const aheadDate = new Date(nowDate.getTime());
    aheadDate.setHours(aheadDate.getHours() + hoursAhead);
    // This was the same fix used for the calendar to be able to get the appropriate timeslots (we grab more than we actually need then filter on them)
    const startDate = moment.utc(nowDate).subtract(2, 'days').startOf('days').toDate();
    const endDate = moment.utc(nowDate).add(2, 'days').endOf('days').toDate();

    const returnToLanding = useCallback(() => {
        location.href = `/facilities-kiosk-landing/${facilityId}`;
    }, [facilityId]);

    useFetchSimple<string>({
        dependencies: [],
        canPerformFetch: true,
        fetchFunc: async (): Promise<string> =>
            FacilitiesClient.getProfilePhoto(
                authContext,
                userContext,
                facilityId,
                PhotoSize['240x240'],
            ),
        onSuccess: (result: string): void => {
            if (isMounted() && result) {
                setEmployeeImage(result);
            }
        },
        onError: (e): void => {
            if (isMounted()) {
                setAlertMessageType(MessageBarType.error);
                setAlertMessage((prevMessage) =>
                    appendMessage('Failed to retrieve profile picture for the user.', prevMessage),
                );
            }
        },
        onFinally: doNothing,
    });

    useFetchSimple<ILogBookRecord>({
        dependencies: [],
        canPerformFetch: true,
        fetchFunc: async (): Promise<ILogBookRecord> => {
            return FacilitiesClient.getLogBookRecord(authContext, userContext, facilityId);
        },
        onSuccess: (result: ILogBookRecord): void => {
            if (isMounted()) {
                setArriving(false);
                setInitialArriving(false);
            }
        },
        onError: (e): void => {
            if (isMounted()) {
                setArriving(true);
                setInitialArriving(true);
            }
        },
        onFinally: doNothing,
    });

    useFetchSimple<IFacilityRecord>({
        dependencies: [],
        canPerformFetch: true,
        fetchFunc: async (): Promise<IFacilityRecord> => {
            return FacilitiesClient.getFacilityRecord(authContext, userContext, facilityId);
        },
        onSuccess: (result: IFacilityRecord): void => {
            if (isMounted()) {
                setFacility(result);
            }
        },
        onError: (e): void => {
            if (isMounted()) {
                setAlertMessageType(MessageBarType.error);
                setAlertMessage((prevMessage) =>
                    appendMessage('Failed to retrieve facility for the user.', prevMessage),
                );
            }
        },
        onFinally: doNothing,
    });

    const {
        theMessage: alertMessage,
        theElement: alertMessageElement,
        setMessage: setAlertMessage,
    } = useMessageBar({
        type: alertMessageType,
    });

    const { isFetching: isFetchingMyReservations } = useFetchSimple<IReservationRecord[]>({
        dependencies: [],
        canPerformFetch: true,
        fetchFunc: async (): Promise<IReservationRecord[]> => {
            return FacilitiesClient.getAllMyReservations(authContext, userContext, facilityId);
        },
        onSuccess: (result: IReservationRecord[] | undefined): void => {
            if (result && isMounted()) {
                setMyReservations(filterReservations(nowDate, aheadDate, result));
            }
        },
        onError: (e): void => {
            if (isMounted()) {
                setAlertMessageType(MessageBarType.error);
                setAlertMessage((prevMessage) =>
                    appendMessage('Failed to retrieve reservations for the user.', prevMessage),
                );
            }
        },
        onFinally: doNothing,
    });

    const { isFetching: isFetchingSeats } = useFetchSimple<IPagedResults<ISeatRecord>>({
        dependencies: [seatsContinuationToken],
        canPerformFetch: seatsContinuationToken === undefined || !!seatsContinuationToken, // first call // subsequent calls
        fetchFunc: async (): Promise<IPagedResults<ISeatRecord>> => {
            return FacilitiesClient.getSeatsByFacilityId(
                authContext,
                userContext,
                facilityId,
                seatsContinuationToken,
            );
        },
        onSuccess: (result: IPagedResults<ISeatRecord>): void => {
            if (isMounted()) {
                setSeats((currentValue) => [...(currentValue ?? [])].concat(result.results));
                setSeatsContinuationToken(result.continuationToken);
            }
        },
        onError: (e): void => {
            if (isMounted()) {
                setAlertMessageType(MessageBarType.error);
                setAlertMessage((prevMessage) =>
                    appendMessage(
                        'Failed to retrieve seats of facility for the user.',
                        prevMessage,
                    ),
                );
            }
        },
        onFinally: doNothing,
    });

    const { isFetching: isFetchingCalendarResponses } = useFetchSimple<
        ISeatAvailabilityCalendarResponse
    >({
        dependencies: [seatStatusContinuationToken],
        canPerformFetch: seatStatusContinuationToken === undefined || !!seatStatusContinuationToken, // first call // subsequent calls
        fetchFunc: async (): Promise<ISeatAvailabilityCalendarResponse> => {
            return FacilitiesClient.getSeatAvailabilityForCalendar(
                authContext,
                userContext,
                facilityId,
                startDate,
                endDate,
                seatStatusContinuationToken,
            );
        },
        onSuccess: (result: ISeatAvailabilityCalendarResponse): void => {
            if (isMounted()) {
                setISeatAvailabilityCalendarResponses((currentValue) => [
                    ...(currentValue ?? []),
                    result,
                ]);
                setSeatStatusContinuationToken(result.continuationToken);
            }
        },
        onError: (e): void => {
            if (isMounted()) {
                setAlertMessageType(MessageBarType.error);
                setAlertMessage((prevMessage) =>
                    appendMessage(
                        'Failed to retrieve seat availability for the user.',
                        prevMessage,
                    ),
                );
            }
        },
        onFinally: doNothing,
    });

    useEffect(() => {
        if (!isFetchingCalendarResponses && iSeatAvailabilityCalendarResponses) {
            const updateSeatValues: ISeatsTimeslotStatusesForDate[] = [];
            for (const isac of iSeatAvailabilityCalendarResponses) {
                updateSeatsTimeslotStatusesForDates(
                    updateSeatValues,
                    filterSeatsTimeslotStatuses(
                        nowDate,
                        aheadDate,
                        isac.seatsTimeslotStatusesForDates,
                    ),
                );
            }
            setSeatsTimeslotStatuses(updateSeatValues);
            setFeaturesLoaded(true);
        }
    }, [iSeatAvailabilityCalendarResponses, isFetchingCalendarResponses]);

    function filterReservations(
        varNowDate: Date,
        varAheadDate: Date,
        varMyReservations: IReservationRecord[],
    ): IReservationRecord[] {
        return varMyReservations.filter((x) =>
            isTimeslotInRange(varNowDate, varAheadDate, x.reservationTimeSlot, true),
        );
    }

    function filterSeatsTimeslotStatuses(
        varNowDate: Date,
        varAheadDate: Date,
        varSeatsTimeslotStatusesForDates: ISeatsTimeslotStatusesForDate[],
    ): ISeatsTimeslotStatusesForDate[] {
        const newSeatsTimeslotStatusesForDates: ISeatsTimeslotStatusesForDate[] = [];

        for (let i = 0; i < varSeatsTimeslotStatusesForDates.length; i++) {
            const filteredTimeslotItems = varSeatsTimeslotStatusesForDates[
                i
            ].dateTimeslots.timeslotItems.filter((x) =>
                isTimeslotInRange(varNowDate, varAheadDate, x),
            );

            // no date timeslots means no timeslot seat statuses as well
            if (filteredTimeslotItems.length === 0) {
                continue;
            }

            const filteredSeatsTimeslotStatusesForDate: ISeatsTimeslotStatusesForDate = {
                dateTimeslots: {
                    date: varSeatsTimeslotStatusesForDates[i].dateTimeslots.date,
                    dateUtcMilliseconds:
                        varSeatsTimeslotStatusesForDates[i].dateTimeslots.dateUtcMilliseconds,
                    timeslotItems: filteredTimeslotItems,
                },
                seatTimeslotsStatusesForDateDict: {},
            };

            const seatIds = Object.keys(
                varSeatsTimeslotStatusesForDates[i].seatTimeslotsStatusesForDateDict,
            );
            for (let j = 0; j < seatIds.length; j++) {
                const filteredTimeslotSeatStatuses = varSeatsTimeslotStatusesForDates[
                    i
                ].seatTimeslotsStatusesForDateDict[seatIds[j]].filter((x) =>
                    isTimeslotInRange(varNowDate, varAheadDate, x.timeslotItem),
                );

                filteredSeatsTimeslotStatusesForDate.seatTimeslotsStatusesForDateDict[
                    seatIds[j]
                ] = filteredTimeslotSeatStatuses;
            }

            newSeatsTimeslotStatusesForDates.push(filteredSeatsTimeslotStatusesForDate);
        }

        return newSeatsTimeslotStatusesForDates;
    }

    function isTimeslotInRange(
        varNowDate: Date,
        varAheadDate: Date,
        timeslotItem: IFacilityTimeslotItem,
        includeLateCheckoutGracePeriod?: boolean,
    ): boolean {
        const lateCheckoutGracePeriodMilliseconds =
            includeLateCheckoutGracePeriod === undefined || !includeLateCheckoutGracePeriod
                ? 0
                : minutesToMilliseconds(LateCheckoutGracePeriodInMinutes);
        return (
            (varNowDate.getTime() >=
                timeslotItem.startDateTimeUTCMilliseconds -
                    minutesToMilliseconds(EarlyCheckinGracePeriodInMinutes) &&
                varNowDate.getTime() <=
                    timeslotItem.endDateTimeUTCMilliseconds +
                        lateCheckoutGracePeriodMilliseconds) || // right now is in between a timeslot
            (varNowDate.getTime() - minutesToMilliseconds(EarlyCheckinGracePeriodInMinutes) <=
                timeslotItem.startDateTimeUTCMilliseconds &&
                varAheadDate.getTime() + lateCheckoutGracePeriodMilliseconds >=
                    timeslotItem.endDateTimeUTCMilliseconds) // everything else between now and how many hours ahead
        );
    }

    function getWelcomeMessage(): JSX.Element {
        if (isArriving === undefined || !facility) {
            return <></>;
        }

        const action = isArriving
            ? toTitleCase(KioskMessaging.Arriving)
            : toTitleCase(KioskMessaging.Leaving);
        return (
            <Stack>
                <Stack.Item className={styles.kioskTitleFont}>
                    {`${action} ${facility.facilityName}`}
                </Stack.Item>
                <Stack.Item style={{ textAlign: 'center' }}>
                    Are you {isArriving ? KioskMessaging.Leaving : KioskMessaging.Arriving} instead{' '}
                    of {isArriving ? KioskMessaging.Arriving : KioskMessaging.Leaving}? If so{' '}
                    <a
                        target='_blank'
                        aria-label='Change between arriving and leaving state'
                        className={globalStyles.link}
                        onClick={(): void => setArriving((prevIsArriving) => !prevIsArriving)}>
                        Click Here
                    </a>
                </Stack.Item>
            </Stack>
        );
    }

    function getBreakMessage(): JSX.Element {
        if (isArriving === undefined || !hasCheckedInReservation()) {
            return <></>;
        }

        return isArriving ? (
            <Stack className={styles.breakContainer} verticalAlign={'center'}>
                <Stack.Item>
                    <span className={styles.breakLeftElement}>
                        <BoldFont>Returning from break,</BoldFont> it is great to have you back!
                    </span>
                </Stack.Item>
            </Stack>
        ) : (
            <Stack className={styles.breakContainer} horizontal verticalAlign={'center'}>
                <Stack.Item>
                    <span className={styles.breakLeftElement}>
                        <BoldFont>Take a break?</BoldFont>&nbsp;&nbsp;&nbsp;We will save your seat,
                        just come back in 15 minutes. See you soon!
                    </span>
                </Stack.Item>
                <Stack.Item grow={1}>
                    <span></span>
                </Stack.Item>
                <Stack.Item className={styles.breakRightElement}>
                    <DefaultButton
                        text='Take a break'
                        disabled={isSubmitted}
                        onClick={async (): Promise<void> => onSubmit(false)}
                    />
                </Stack.Item>
            </Stack>
        );
    }

    function getEquipmentMessage(): JSX.Element {
        if (isArriving === undefined || !equipment) {
            return <></>;
        }

        const action = isArriving ? KioskMessaging.CheckedOut : KioskMessaging.CheckedIn;
        const hasEquipmentToCheckinCheckout = equipment.some(
            (x) => x.checkinCheckoutInfo?.checkedOut === !isArriving,
        );
        return hasEquipmentToCheckinCheckout ? (
            <span className={styles.equipmentMessageText}>
                {`Selected badges will be ${action}`}
            </span>
        ) : (
            <span className={styles.equipmentMessageText}>
                {`You have no equipment to be ${action}`}
            </span>
        );
    }

    function onCancelReservation(reservation: IReservationRecord, seat: ISeatRecord): void {
        setMyReservations((prevReservations) => {
            return prevReservations?.filter((x) => x.id !== reservation.id);
        });

        setSeatsTimeslotStatuses((prevSeatsTimeslotStatuses) => {
            const newSeatsTimeslotStatuses = [...(prevSeatsTimeslotStatuses ?? [])];

            updateSeatsTimeslotStatusesForCancelledReservation(
                newSeatsTimeslotStatuses,
                reservation,
                seat,
            );

            return newSeatsTimeslotStatuses;
        });
    }

    function updateSeatsTimeslotStatusesForCancelledReservation(
        varSeatsTimeslotStatuses: ISeatsTimeslotStatusesForDate[],
        reservation: IReservationRecord,
        seat: ISeatRecord,
    ): void {
        for (let i = 0; i < varSeatsTimeslotStatuses.length; i++) {
            const timeslotSeatStatuses =
                varSeatsTimeslotStatuses[i].seatTimeslotsStatusesForDateDict[seat.id];
            const timeslotSeatStatus = timeslotSeatStatuses?.find((x) =>
                areTimeSlotItemsEqual(x.timeslotItem, reservation.reservationTimeSlot),
            );
            if (timeslotSeatStatus) {
                timeslotSeatStatus.seatStatus.status = getAvailableSeatStatus(seat);
                timeslotSeatStatus.seatStatus.reservationState = undefined;
                return;
            }
        }
    }

    useEffect(() => {
        if (isArriving !== undefined && equipment) {
            const newSelectedEquipment = equipment.filter(
                (x) => x.checkinCheckoutInfo?.checkedOut === !isArriving,
            );
            setSelectedEquipment(newSelectedEquipment);
        }
    }, [isArriving, equipment]);

    useEffect(() => {
        // we may not have any reservations
        if (seatsTimeslotStatuses && seats && myReservations) {
            const seatDict: Dictionary<ISeatRecord> = {};
            for (let i = 0; i < seats.length; i++) {
                seatDict[seats[i].id] = seats[i];
            }

            setISeatsTableRows(() => {
                const newSeatsTableRows: ISeatsTableRow[] = myReservations.map((r) => {
                    return {
                        timeslotItem: r.reservationTimeSlot,
                        seat: seatDict[r.seatId] ?? '',
                        reservation: r,
                    };
                });

                const existingReservationTimeslots = new Set<number>(
                    myReservations.map((r) => r.reservationTimeSlot.startDateTimeUTCMilliseconds),
                );

                for (let i = 0; i < seatsTimeslotStatuses.length; i++) {
                    for (
                        let j = 0;
                        j < seatsTimeslotStatuses[i].dateTimeslots.timeslotItems.length;
                        j++
                    ) {
                        const timeslotItem =
                            seatsTimeslotStatuses[i].dateTimeslots.timeslotItems[j];
                        if (
                            !existingReservationTimeslots.has(
                                timeslotItem.startDateTimeUTCMilliseconds,
                            )
                        ) {
                            newSeatsTableRows.push({
                                timeslotItem: timeslotItem,
                            });
                        }
                    }
                }

                newSeatsTableRows.sort((a, r) =>
                    numCmp(
                        a.timeslotItem.startDateTimeUTCMilliseconds,
                        r.timeslotItem.startDateTimeUTCMilliseconds,
                    ),
                );

                return newSeatsTableRows;
            });
        }
    }, [seats, myReservations, seatsTimeslotStatuses, isArriving]);

    async function getFacilityToken(): Promise<IFacilitiesTokenResult | undefined> {
        try {
            if (facilityId) {
                const token = await userContext.refreshFacilitiesToken();
                if (FacilitiesClient.canAccessFacility(token, facilityId)) {
                    setFacilitiesToken(token);
                    return token;
                }
            }
        } catch (error) {
            if (isMounted()) {
                setAlertMessageType(MessageBarType.error);
                setAlertMessage((prevMessage) =>
                    appendMessage('Failed to retrieve facilities token for the user.', prevMessage),
                );
            }
            if (authContext.isKioskRenderMode()) {
                throw error;
            }
        }
    }

    async function getEmployee(): Promise<void> {
        try {
            const facilityToken = await getFacilityToken();
            if (facilityId && facilityToken) {
                const personnelId = FacilitiesClient.providePersonnelFromFacilitiesToken(
                    facilityToken,
                );

                if (personnelId) {
                    const localEmployee = await EmployeeClient.getEmployeeByAliasOrId(
                        authContext,
                        personnelId,
                    );

                    if (isMounted()) {
                        setEmployee(localEmployee);
                    }
                }
            }
        } catch (ex) {
            if (isMounted()) {
                setAlertMessageType(MessageBarType.error);
                setAlertMessage((prevMessage) =>
                    appendMessage('Failed to retrieve employee record for the user.', prevMessage),
                );
            }

            if (authContext.isKioskRenderMode()) {
                throw ex;
            }
        }
    }

    async function getUserEquipment(): Promise<void> {
        try {
            if (facilityId) {
                const localEquipment = await FacilitiesClient.getSmartCardEquipmentRecords(
                    authContext,
                    userContext,
                    facilityId,
                    true, // this flag is used to query for the current user's equipment
                );

                if (isMounted()) {
                    setEquipment(localEquipment);
                }
            }
        } catch (ex) {
            if (isMounted()) {
                setAlertMessageType(MessageBarType.error);
                setAlertMessage((prevMessage) =>
                    appendMessage('Failed to retrieve equipment for the user.', prevMessage),
                );
            }
        }
    }

    function appendMessage(newMessage: string, prevMessage?: string): string {
        if (!prevMessage) {
            return newMessage;
        }

        return `${prevMessage} ${newMessage}`;
    }

    const onSelectEquipment = (equipmentRecord: IEquipmentRecord): void => {
        const isAlreadySelected =
            selectedEquipment?.find((x) => x.id === equipmentRecord.id) !== undefined;
        if (isAlreadySelected) {
            setSelectedEquipment((prevSelectedEquipment) => {
                return prevSelectedEquipment?.filter((x) => x.id !== equipmentRecord.id);
            });
        } else {
            setSelectedEquipment((prevSelectedEquipment) => {
                const newSelectedEquipment = [...(prevSelectedEquipment ?? [])];
                newSelectedEquipment.push(equipmentRecord);
                return newSelectedEquipment;
            });
        }
    };

    function getBadgeTopStyle(): CSSProperties {
        return {
            background:
                employee === undefined || employee.isFTE
                    ? fullTimeEmployeeCardColor
                    : vendorEmployeeCardColor,
            height: 250,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
    }

    function getCheckMarkStyle(): CSSProperties {
        return {
            fontSize: '20px',
            paddingLeft: '6px',
            color: isSubmitted ? disabledButtonTextColor : 'white',
        };
    }

    function getEquipmentCardStyle(varEquipment: IEquipmentRecord): CSSProperties {
        const backgroundColor =
            SmartCardArray.find((x) => varEquipment.type.toUpperCase() === x.value)?.color ??
            'grey';
        return {
            borderRadius: '10px',
            background: backgroundColor,
            width: '100%',
            height: '100%',
            paddingTop: '110px',
            paddingBottom: '10px',
            alignItems: 'center',
        };
    }

    function getEquipmentCheckbox(varEquipment: IEquipmentRecord): JSX.Element {
        const isSelected = isSelectedEquipment(varEquipment.id);

        return (
            <Stack.Item
                style={{
                    width: '32px',
                    height: '32px',
                    backgroundColor: isSubmitted
                        ? disabledButtonColor
                        : isSelected
                        ? checkboxBlue
                        : 'transparent',
                    border: isSubmitted || isSelected ? '' : '1px solid black',
                }}
                onClick={(): void => {
                    if (!isSubmitted) {
                        onSelectEquipment(varEquipment);
                    }
                }}>
                {isSelected && <Icon iconName={IconNames.CheckMark} style={checkMarkStyle} />}
            </Stack.Item>
        );
    }

    function getEmployeeBadge(): JSX.Element {
        return (
            <Stack className={styles.badge} verticalAlign='center'>
                <Stack.Item style={badgeTopStyle}>
                    <Stack>
                        <Stack.Item style={{ paddingTop: '8px' }}>
                            {employeeImage && (
                                <img
                                    alt='A picture of the employee for their Microsoft badge'
                                    src={employeeImage}
                                    className={styles.profilePicture}
                                />
                            )}
                            {!employeeImage && (
                                <Icon
                                    iconName={IconNames.Contact}
                                    className={styles.profilePictureMissing}
                                />
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item className={styles.badgeBottom}>
                    {employee && <h1>{employee?.preferredFirstName}</h1>}
                    <Spacer marginTop={100} />
                    <img alt="Microsoft's Logo" src={msLogo} className={styles.companyLogo} />
                </Stack.Item>
            </Stack>
        );
    }

    function getPostSubmitMessage(): JSX.Element {
        if (isArriving === undefined) {
            return <></>;
        }

        let message: JSX.Element;
        if (isArriving) {
            message = !!reservationNow ? (
                <Stack>
                    <Stack.Item>
                        <h1 className={styles.kioskTitleFont} style={postSubmitMessageMarginStyle}>
                            Proceed to seat:
                        </h1>
                    </Stack.Item>
                    <Stack.Item style={postSubmitSeatNameMarginStyle}>
                        <BoldFont className={styles.postSubmitSeatName}>
                            {seats?.find((x) => x.id === reservationNow.seatId)?.seatName}
                        </BoldFont>
                    </Stack.Item>
                </Stack>
            ) : (
                <Stack.Item>
                    <h1 className={styles.kioskTitleFont} style={postSubmitMessageMarginStyle}>
                        Welcome.
                    </h1>
                </Stack.Item>
            );
        } else {
            message = (
                <Stack.Item>
                    <Stack.Item>
                        <h1 className={styles.kioskTitleFont} style={postSubmitMessageMarginStyle}>
                            Please rate your reservation experience before you go:
                        </h1>
                    </Stack.Item>
                    <Stack
                        styles={{
                            root: {
                                alignItems: 'center',
                                paddingTop: 100,
                            },
                        }}>
                        <Rating
                            onChange={submitUserFeedbackAndLogout}
                            allowZeroStars={true}
                            defaultRating={0}
                            styles={{
                                ratingStarIsSmall: {
                                    fontSize: '100px',
                                    lineHeight: '100px',
                                    height: '100px',
                                },
                                ratingStarFront: {
                                    color: '#d4af37',
                                },
                                ratingButton: {
                                    ':hover': {
                                        '.ms-RatingStar-front': {
                                            color: '#d4af37',
                                        },
                                    },
                                },
                            }}
                        />
                    </Stack>
                </Stack.Item>
            );
        }

        return (
            <Stack>
                {message}
                <Stack.Item grow={1}>
                    <span></span>
                </Stack.Item>
                <Stack.Item className={globalStyles.largeFont} style={postSubmitMessageMarginStyle}>
                    <BoldFont>{`Signing out in ${forcedLogoutSeconds} seconds`}</BoldFont>
                </Stack.Item>
            </Stack>
        );
    }

    async function submitUserFeedbackAndLogout(
        event: React.FormEvent<HTMLElement>,
        rating?: number,
    ): Promise<void> {
        const userFeedbackRequest: IFacilityUserFeedbackRequest = {
            rating: rating ?? 0,
        };
        try {
            await FacilitiesClient.createFacilityUserFeedbackRecord(
                authContext,
                userContext,
                facilityId,
                userFeedbackRequest,
            );
        } catch (e) {
            throw 'An error occurred submitting the user feedback.';
        }
        returnToLanding();
    }

    const styles = mergeStyleSets({
        mapView: {
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 173px)',
        },
        badge: {
            width: '275px',
            alignItems: 'baseline',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.3)',
        },
        main: {
            margin: '0 10px',
        },
        profilePicture: {
            width: 180,
            height: 180,
            borderRadius: '50%',
        },
        profilePictureMissing: {
            fontSize: '8em',
            textAlign: 'center',
            borderRadius: '50%',
            width: 180,
            height: 180,
            backgroundColor: 'white',
        },
        badgeBottom: {
            padding: '0 20px 20px',
        },
        companyLogo: {
            width: '100px',
        },
        horizontalBarTitle: {
            margin: 0,
            padding: '6px 0',
        },
        seatsWrapper: {
            minWidth: '40%',
            flexGrow: 1,
            paddingRight: '3px',
        },
        equipment: {
            width: '40%',
            paddingBottom: '10px',
            height: '100%',
        },
        equipmentMessage: {
            background: '#f0f0f0',
            padding: '0 10px',
        },
        equipmentMessageText: {
            position: 'relative',
            top: '9px',
        },
        equipmentCardWrapper: {
            background: '#f0f0f0',
            padding: '18px 5px',
        },
        selectedEquipmentCard: {
            borderRadius: '15px',
            width: 180,
            height: 300,
            padding: 5,
            maxWidth: '180px',
            margin: `0 ${equipment && 40 / equipment?.length}px`,
            backgroundColor: '#ffffff',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.3)',
        },
        unselectedEquipmentCard: {
            borderRadius: '15px',
            backgroundColor: '#ffffff',
            width: 180,
            height: 300,
            padding: 5,
            margin: `0 ${equipment && 40 / equipment?.length}px`,
            boxShadow: 'none',
        },
        actionButton: {
            padding: 20,
            fontSize: 20,
        },
        breakContainer: {
            backgroundColor: '#f7ecc6',
            height: '52px',
            marginBottom: '12px',
        },
        breakLeftElement: {
            fontSize: '18px',
            paddingLeft: '10px',
        },
        breakRightElement: {
            fontSize: '18px',
            paddingRight: '10px',
        },
        equipmentCardWhiteArea: {
            backgroundColor: 'white',
            width: '135px',
            height: '160px',
            paddingTop: '80px',
        },
        smartCardName: {
            fontSize: '28px',
            marginTop: '-70px',
        },
        postSubmitSeatName: {
            fontSize: '176px',
        },
        kioskTitleFont: {
            fontSize: '48px',
            fontWeight: 900,
        },
        kioskHelloFont: {
            fontSize: '25px',
            fontWeight: 700,
            textIndent: '10px',
        },
    });

    useEffect(() => {
        getEmployee();
    }, []);

    useEffect(() => {
        if (facility?.isEquipmentEnabled) {
            getUserEquipment();
        }
    }, [facility]);

    const [{ sortColumn }, sortColumnHandler] = useSortColumnHandler(
        FacilitiesKioskPageTableColumnKeys.TimeSlot,
        1,
    );

    function goToMap(iTimeAvailability: ITimeAvailability, isViewOnly: boolean): void {
        setITimeAvailability(iTimeAvailability);
        setIsViewOnly(isViewOnly);
        setPostLoadedIndoorMapDivClass(false);
        setShowIndoorMapView(true);
        setView(IKioskView.Map);
    }

    function filterSeatStatuses(timeslotItem: IFacilityTimeslotItem): ISeatStatus[] {
        if (!seatsTimeslotStatuses) {
            return [];
        }

        const seatStatuses: ISeatStatus[] = [];

        for (let i = 0; i < seatsTimeslotStatuses.length; i++) {
            const timeslotSeatStatuses = Object.values(
                seatsTimeslotStatuses[i].seatTimeslotsStatusesForDateDict,
            );
            for (let j = 0; j < timeslotSeatStatuses.length; j++) {
                const timeslotSeatStatus = timeslotSeatStatuses[j].find((x) =>
                    areTimeSlotItemsEqual(x.timeslotItem, timeslotItem),
                );
                if (timeslotSeatStatus) {
                    seatStatuses.push(timeslotSeatStatus.seatStatus);
                }
            }
        }

        return seatStatuses;
    }

    function onMapFacilitySeatStatus(updates: IReservationPromiseInstruction[]): void {
        const updatedISeatAvailabilityCalendarResponses = [...iSeatAvailabilityCalendarResponses];
        if (updates && updates.length > 0) {
            for (const updatedISeatAvailabilityCalendarResponse of updatedISeatAvailabilityCalendarResponses) {
                for (const update of updates) {
                    switch (update.type) {
                        case 'make':
                            applyMakeInstruction(update, updatedISeatAvailabilityCalendarResponse);
                            break;
                        case 'cancel':
                            applyCancelInstruction(
                                update,
                                updatedISeatAvailabilityCalendarResponse,
                            );
                            break;
                        case 'reschedule':
                            applyRescheduleInstruction(
                                update,
                                updatedISeatAvailabilityCalendarResponse,
                            );
                            break;
                    }
                }
            }
        }

        callUpdateReservations();
        setISeatAvailabilityCalendarResponses(updatedISeatAvailabilityCalendarResponses);
        setITimeAvailability(undefined);
        setView(IKioskView.Main);
    }

    async function callUpdateReservations(): Promise<void> {
        const reservations = await FacilitiesClient.getAllMyReservations(
            authContext,
            userContext,
            facilityId,
        );
        if (reservations) {
            setMyReservations(filterReservations(nowDate, aheadDate, reservations));
        }
    }

    async function checkinCheckoutEquipment(): Promise<boolean> {
        if (!selectedEquipment || selectedEquipment.length === 0 || !facility) {
            return true;
        }

        try {
            await FacilitiesClient.multiCheckInOutEquipment(
                authContext,
                userContext,
                selectedEquipment.map((x) => x.id),
                facility.id,
                isArriving ? EquipmentState.CheckOut : EquipmentState.CheckIn,
            );

            return true;
        } catch (e) {
            return false;
        }
    }

    async function checkinCheckoutReservation(includeReservation: boolean): Promise<boolean> {
        if (
            isArriving === undefined ||
            !myReservations ||
            myReservations.length === 0 ||
            !facility ||
            !includeReservation
        ) {
            return true;
        }

        const nowDateTimeUtcMilliseconds = new Date().getTime();
        const expectedState = isArriving ? ReservationState.Confirmed : ReservationState.CheckedIn;
        const reservationNow = myReservations.find(
            (x) =>
                nowDateTimeUtcMilliseconds >=
                    x.reservationTimeSlot.startDateTimeUTCMilliseconds -
                        minutesToMilliseconds(EarlyCheckinGracePeriodInMinutes) &&
                nowDateTimeUtcMilliseconds <=
                    x.reservationTimeSlot.endDateTimeUTCMilliseconds +
                        minutesToMilliseconds(LateCheckoutGracePeriodInMinutes) &&
                x.state === expectedState,
        );

        if (!reservationNow) {
            return true;
        }

        setReservationNow(reservationNow);

        try {
            if (isArriving) {
                await FacilitiesClient.checkInSeatReservation(
                    authContext,
                    userContext,
                    facility.id,
                    reservationNow.id,
                );
            } else {
                await FacilitiesClient.checkOutSeatReservation(
                    authContext,
                    userContext,
                    facility.id,
                    reservationNow.id,
                );
            }

            return true;
        } catch (e) {
            return false;
        }
    }

    async function createOrDeleteLogBookRecord(): Promise<boolean> {
        // if we initially were leaving and submitted as arriving or initially were arriving and submitted as leaving do nothing
        if (isArriving === undefined || !facility || isArriving !== isInitialArriving) {
            return true;
        }

        try {
            const serializedToken = await FacilitiesClient.getFacilitiesToken(authContext);
            const deserializedToken = FacilitiesClient.deserializeFacilitiesToken(serializedToken);
            if (isArriving) {
                await FacilitiesClient.createLogBookRecord(
                    authContext,
                    userContext,
                    facility.id,
                    deserializedToken?.personnelId,
                );
            } else {
                await FacilitiesClient.deleteLogBookRecord(
                    authContext,
                    userContext,
                    facility.id,
                    deserializedToken?.personnelId,
                );
            }

            return true;
        } catch (e) {
            return false;
        }
    }

    function isSelectedEquipment(equipmentId: string): boolean {
        return !!selectedEquipment && selectedEquipment.some((x) => x.id === equipmentId);
    }

    async function onSubmit(includeReservation: boolean): Promise<void> {
        setSubmitted(true);

        const [
            hasSuccessfulCheckinCheckoutEquipment,
            hasSuccessfulCheckinCheckoutReservation,
            hasSuccessfulCreateOrDeleteLogBookRecord,
        ] = await Promise.all([
            checkinCheckoutEquipment(),
            checkinCheckoutReservation(includeReservation),
            createOrDeleteLogBookRecord(),
        ]);

        setView(IKioskView.PostSubmit);

        const errorMessages: string[] = [];

        if (!hasSuccessfulCheckinCheckoutEquipment) {
            errorMessages.push(
                `Your equipment was unsuccessfully ${
                    isArriving ? KioskMessaging.CheckedOut : KioskMessaging.CheckedIn
                }.`,
            );
        }

        if (!hasSuccessfulCheckinCheckoutReservation) {
            errorMessages.push(
                `Your reservation was unsuccessfully ${
                    isArriving ? KioskMessaging.CheckedIn : KioskMessaging.CheckedOut
                }.`,
            );
        }

        if (!hasSuccessfulCreateOrDeleteLogBookRecord) {
            errorMessages.push(
                `Your book log was unsuccessfully ${
                    isArriving ? KioskMessaging.Created : KioskMessaging.Deleted
                }.`,
            );
        }

        if (errorMessages.length > 0) {
            setAlertMessageType(MessageBarType.error);
            setAlertMessage(errorMessages.join(' '));
        }

        setTimeout(() => {
            returnToLanding();
        }, forcedLogoutSeconds * 1000);
    }

    function hasCheckedInReservation(): boolean {
        return (
            !!myReservations && myReservations.some((x) => x.state === ReservationState.CheckedIn)
        );
    }

    function canTouchEquipment(varEquipmentRecord: IEquipmentRecord): boolean {
        return (
            isArriving !== undefined &&
            varEquipmentRecord.checkinCheckoutInfo?.checkedOut === !isArriving
        );
    }

    const [isLoadingTableData, setLoadingTableData] = useState(false);

    useEffect(() => {
        if (isFetchingMyReservations || isFetchingSeats || isFetchingCalendarResponses) {
            setLoadingTableData(true);
        } else {
            setLoadingTableData(false);
        }
    }, [isFetchingMyReservations, isFetchingSeats, isFetchingCalendarResponses]);

    const tableColumns = facility
        ? facilitiesKioskPageTableColumns({
              facility,
              isArriving,
              isSubmitted,
              iSeatAvailabilityCalendarResponses,
              sortColumnHandler,
              sortColumn,
              goToMap,
              onMapFacilitySeatStatus,
          })
        : [];

    function panelTitle(): string {
        if (!facility) {
            return '';
        }
        let title = `${facility?.facilityName}`;
        if (iTimeAvailability) {
            const startTime = moment(iTimeAvailability.startTime).format(TimeFormats.H_mmA);
            const endTime = moment(iTimeAvailability.endTime).format(TimeFormats.H_mmA);
            title = `${title} - ${startTime} to ${endTime}`;
        }
        return title;
    }

    const isSvgMapEnabled = useFeatureFlag(FeatureFlagKeys.enableSvgMap).enabled;
    const shouldUseSvgMap = isSvgMapEnabled && !!facility?.svgMap;

    return (
        <>
            {!shouldUseSvgMap && (
                <Panel
                    isOpen={view === IKioskView.Map}
                    onDismiss={(): void => {
                        setView(IKioskView.Main);
                        setITimeAvailability(undefined);
                        callUpdateReservations();
                    }}
                    onDismissed={(): void => {
                        setMapLoaded(false);
                    }}
                    type={PanelType.extraLarge}
                    allowTouchBodyScroll={true}
                    isLightDismiss
                    closeButtonAriaLabel='Close'
                    headerText={panelTitle()}>
                    <FacilitiesAzureIndoorMap
                        theme={'light'}
                        facility={facility}
                        facilitySeats={seats}
                        featuresLoaded={hasMapLoaded}
                        isViewOnly={isViewOnly}
                        visible={view === IKioskView.Map}
                        timeAvailability={iTimeAvailability}
                        onFeaturesLoaded={(): void => {
                            setMapLoaded(true);
                        }}
                        showIndoorMapView={showIndoorMapView}
                        updateFacilitySeatStatuses={onMapFacilitySeatStatus}>
                        <FacilitiesLegend />
                    </FacilitiesAzureIndoorMap>
                </Panel>
            )}
            {shouldUseSvgMap && view === IKioskView.Map && (
                <FacilitiesKioskPanel
                    onDismiss={(): void => {
                        setView(IKioskView.Main);
                        setITimeAvailability(undefined);
                        callUpdateReservations();
                    }}
                    headerText={panelTitle()}>
                    <FacilitiesSvgIndoorMap
                        facility={facility}
                        updateFacilitySeatStatuses={onMapFacilitySeatStatus}
                        timeAvailability={iTimeAvailability}
                        facilitySeats={seats}
                        isViewOnly={isViewOnly}
                    />
                </FacilitiesKioskPanel>
            )}
            <CheckAccess
                requiredAccessTypeAny={[
                    {
                        serviceProvider: ServiceProviderType.Facility,
                        value: FacilityUserType.UserRole,
                    },
                ]}
                accessDeniedContent={<></>}>
                <PageLoadingSpinner
                    label='Loading kiosk...'
                    ariaLive='assertive'
                    isLoading={isArriving === undefined}
                    labelPosition='top'>
                    <Stack className={styles.main}>
                        {alertMessage && (
                            <>
                                {alertMessageElement()}
                                <Spacer marginTop={10} />
                            </>
                        )}
                        {view === IKioskView.Main && (
                            <Stack>
                                <Stack.Item
                                    style={{
                                        alignSelf: 'center',
                                        padding: '25px',
                                    }}>
                                    {getWelcomeMessage()}
                                </Stack.Item>
                                <Stack.Item>{getBreakMessage()}</Stack.Item>
                                {employee && (
                                    <Stack.Item className={styles.kioskHelloFont}>
                                        Welcome, {employee?.preferredFirstName}!
                                    </Stack.Item>
                                )}
                                <Stack.Item>
                                    <Stack horizontal>
                                        <Stack.Item style={{ paddingRight: '3px' }}>
                                            {getEmployeeBadge()}
                                        </Stack.Item>
                                        <Stack.Item className={styles.seatsWrapper}>
                                            <HorizontalBar>
                                                <Stack.Item>
                                                    <h3 className={styles.horizontalBarTitle}>
                                                        Seats
                                                    </h3>
                                                </Stack.Item>
                                            </HorizontalBar>
                                            {employee && facility && (
                                                <Table
                                                    tableColumns={tableColumns}
                                                    rows={ISeatsTableRows}
                                                    tableName='Seats'
                                                    shimmerLabel={'Loading seats...'}
                                                    shimmerLines={5}
                                                    isFetchingData={isLoadingTableData}
                                                />
                                            )}
                                        </Stack.Item>
                                        {employee && facility && facility.isEquipmentEnabled && (
                                            <Stack.Item className={styles.equipment}>
                                                <HorizontalBar>
                                                    <Stack.Item className={horizontalBarTitleStyle}>
                                                        <h3 className={styles.horizontalBarTitle}>
                                                            Equipment
                                                        </h3>
                                                    </Stack.Item>
                                                </HorizontalBar>
                                                <Stack.Item className={styles.equipmentMessage}>
                                                    {getEquipmentMessage()}
                                                </Stack.Item>
                                                <Stack
                                                    horizontal
                                                    horizontalAlign='center'
                                                    className={styles.equipmentCardWrapper}>
                                                    {isArriving !== undefined &&
                                                        equipment &&
                                                        equipment.map((e) => (
                                                            <Stack
                                                                key={e.id}
                                                                horizontalAlign={'center'}>
                                                                <Stack
                                                                    className={
                                                                        isSelectedEquipment(e.id)
                                                                            ? styles.selectedEquipmentCard
                                                                            : styles.unselectedEquipmentCard
                                                                    }
                                                                    onClick={(): void => {
                                                                        if (
                                                                            canTouchEquipment(e) &&
                                                                            !isSubmitted
                                                                        ) {
                                                                            onSelectEquipment(e);
                                                                        }
                                                                    }}>
                                                                    <Stack
                                                                        style={getEquipmentCardStyle(
                                                                            e,
                                                                        )}>
                                                                        <Stack
                                                                            className={
                                                                                styles.equipmentCardWhiteArea
                                                                            }
                                                                            horizontalAlign={
                                                                                'center'
                                                                            }>
                                                                            <Stack.Item
                                                                                className={
                                                                                    styles.smartCardName
                                                                                }>
                                                                                {
                                                                                    SmartCardArray.find(
                                                                                        (x) =>
                                                                                            e.type.toUpperCase() ===
                                                                                            x.value,
                                                                                    )?.text
                                                                                }
                                                                            </Stack.Item>
                                                                            <Stack.Item>
                                                                                <img
                                                                                    alt='An image of the chip on a smart card'
                                                                                    src={
                                                                                        smartCardChip
                                                                                    }
                                                                                />
                                                                            </Stack.Item>
                                                                        </Stack>
                                                                    </Stack>
                                                                </Stack>
                                                                <Stack
                                                                    horizontal
                                                                    style={{ paddingTop: '16px' }}>
                                                                    {canTouchEquipment(e) ? (
                                                                        getEquipmentCheckbox(e)
                                                                    ) : (
                                                                        <Stack.Item
                                                                            style={{
                                                                                padding:
                                                                                    '5px 0 5px 5px',
                                                                            }}>
                                                                            {`Already ${
                                                                                isArriving
                                                                                    ? KioskMessaging.CheckedOut
                                                                                    : KioskMessaging.CheckedIn
                                                                            }`}
                                                                        </Stack.Item>
                                                                    )}
                                                                </Stack>
                                                            </Stack>
                                                        ))}
                                                </Stack>
                                            </Stack.Item>
                                        )}
                                    </Stack>
                                </Stack.Item>
                                <Spacer marginTop={40} />
                                <Stack.Item>
                                    <Stack>
                                        <Stack.Item>
                                            <HorizontalBar
                                                styles={{ padding: '12px' }}
                                                horizontalAlign='space-between'>
                                                <DefaultButton
                                                    className={styles.actionButton}
                                                    text='Cancel'
                                                    disabled={isSubmitted}
                                                    onClick={returnToLanding}
                                                />

                                                <PrimaryButton
                                                    onClick={(): Promise<void> => onSubmit(true)}
                                                    className={styles.actionButton}
                                                    text='Submit'
                                                    disabled={isSubmitted}
                                                />
                                            </HorizontalBar>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        )}

                        {view === IKioskView.PostSubmit && (
                            <>
                                <Spacer marginTop={218} />
                                <Stack horizontal horizontalAlign='center'>
                                    {getEmployeeBadge()}
                                    {getPostSubmitMessage()}
                                </Stack>
                            </>
                        )}
                    </Stack>
                </PageLoadingSpinner>
            </CheckAccess>
        </>
    );
}
