import React, { useContext, useEffect, useState } from 'react';
import {
    manageAttrBreadcrumbs,
    manageEligBreadcrumbs,
} from 'components/eligibilities/eligibilities-breadcrumbs';
import { useParams, Redirect } from 'react-router-dom';
import CheckRole from 'components/common/check-role';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { Role } from 'configs/roles';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import Tabs, { TabbedContent } from 'components/common/tabs';
import Attributes from 'components/eligibilities/attributes/attributes';
import Eligibilities from 'components/eligibilities/eligibilities/manage/eligibilities';

export default function ManageEligibilities(): JSX.Element {
    const breadCrumbContext = useContext(BreadCrumbContext);
    const [redirectTo, setRedirectTo] = useState<string>();
    const { tab } = useParams<{ tab?: string }>();

    const onTabChange = (itemKey?: string): void => {
        if (itemKey === 'attributes' || itemKey === 'eligibilities') {
            setRedirectTo(`/profile/manage/${itemKey}`);
        }
    };

    useEffect(() => {
        tab === 'attributes'
            ? breadCrumbContext.setBreadCrumbs([...manageAttrBreadcrumbs])
            : breadCrumbContext.setBreadCrumbs([...manageEligBreadcrumbs]);
    }, [tab]);

    useEffect(() => {
        if (redirectTo) {
            setRedirectTo('');
        }
    }, [redirectTo]);

    if (redirectTo) return <Redirect push={true} to={redirectTo} />;

    return (
        <CheckRole
            requiredRolesAny={[Role.AttributesRead, Role.EligibilitiesRead]}
            redirectNotInRole={AccessDeniedURL}>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <div>
                <Tabs selectedKey={tab} onChange={onTabChange}>
                    <TabbedContent key='Attributes' tabHeader='Attributes' itemKey={'attributes'}>
                        <Attributes />
                    </TabbedContent>
                    <TabbedContent
                        key='Eligibilities'
                        tabHeader='Eligibilities'
                        itemKey={'eligibilities'}>
                        <Eligibilities />
                    </TabbedContent>
                </Tabs>
            </div>
        </CheckRole>
    );
}
