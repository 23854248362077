import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import {
    changeMap,
    createAzureMap,
    createSeatStatusColorShapes,
    FacilitiesAzureIndoorMapBaseProps,
    getFacilitySeatDict,
    getIndoorManagerOptions,
    getSeatFeatureClicked,
    defaultMapContainerId,
    AzureMapThemeColor,
    addPolygonLayer,
    addZoomControl,
    addLineLayer,
    createPopup,
    getFeatureCenterPosition,
} from 'components/facilities/facilities-azure-indoor-map/facilities-azure-indoor-map-common';
import {
    IFacilityRecord,
    ISeatRecord,
    ReservationState,
    SeatQualifiers,
    SeatStatuses,
} from 'clients/facilities-client';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import CancelReservationModalActionButton from 'components/facilities/facilities-reservations/modals/facilities-reservation-cancel-modal-action-button';
import ChangeReservationModal from 'components/facilities/facilities-reservations/modals/facilities-reservation-change-modal';
import { mergeStyleSets } from '@fluentui/react';
import { azureMapLegendClass } from 'components/facilities/facilities-legend';
import FacilitiesReservationModalV2 from 'components/facilities/facilities-reservations/modals/facilities-reservation-modal-v2';
import { ITimeAvailability } from 'components/facilities/facilities-page';
import {
    ISeatSlot,
    seatSlotsForSeats,
} from 'components/facilities/common/facilities-timeslot-utils';
import {
    ICancelationInfo,
    IMakeReservationInfo,
    IRescheduleInfo,
    IReservationInstructions,
    IReservationPromiseInstruction,
    createCancelPromises,
    createMakePromises,
    createReschedulePromises,
} from 'components/facilities/facilities-reservations/modals/facilities-reservation-instruction-utils';
import FacilitiesSvgMap from 'components/facilities/facilities-svg-map/facilities-svg-map';

export interface FacilitiesSvgIndoorMapProps {
    facility?: IFacilityRecord;
    facilitySeats?: ISeatRecord[];
    isViewOnly?: boolean;
    updateFacilitySeatStatuses?: (updates: IReservationPromiseInstruction[]) => void;
    timeAvailability?: ITimeAvailability;
}

interface IEventObject {
    facilitySeats?: ISeatRecord[];
    seatSlots?: ISeatSlot[];
    isViewOnly?: boolean;
}

export default function FacilitiesSvgIndoorMap(props: FacilitiesSvgIndoorMapProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const [selectedSeat, setSelectedSeat] = useState<ISeatRecord>();
    const [isReservationDialogOpen, setReservationDialogOpen] = useState<boolean>(false);
    const [isCancelReservationDialogOpen, setCancelReservationDialogOpen] = useState<boolean>(
        false,
    );
    const [isChangeReservationDialogOpen, setChangeReservationDialogOpen] = useState<boolean>(
        false,
    );

    const [seatSlots, setSeatSlots] = useState<ISeatSlot[]>([]);
    const [eventObj] = useState<IEventObject>({
        facilitySeats: [],
        seatSlots: [],
        isViewOnly: false,
    });
    const eventObjRef = useRef(eventObj);

    async function resolveCalendarResponses(
        timeAvailability: ITimeAvailability,
        facilitySeats: ISeatRecord[],
    ): Promise<void> {
        if (timeAvailability.calendarResponses) {
            const results = await timeAvailability.calendarResponses;
            const varSeatSlots = seatSlotsForSeats(
                timeAvailability.startTime,
                timeAvailability.endTime,
                results,
                facilitySeats,
            );
            setSeatSlots(varSeatSlots);
        }
    }

    useEffect(() => {
        if (props.timeAvailability && props.facilitySeats) {
            setSelectedSeat(undefined);
            resolveCalendarResponses(props.timeAvailability, props.facilitySeats);
        }
    }, [props.timeAvailability, props.facilitySeats]);

    useEffect(() => {
        eventObj.facilitySeats = props.facilitySeats;
        eventObj.seatSlots = seatSlots;
        eventObj.isViewOnly = props.isViewOnly;
    }, [props.facilitySeats, seatSlots, props.isViewOnly]); // put all fields used by the map event listeners here

    useEffect(() => {
        if (selectedSeat && seatSlots && props.facility) {
            const selectedSeatSlot = seatSlots.find((x) => x.seatId === selectedSeat.id)!;
            const existingAssignedSeats = seatSlots.filter(
                (x) => x.status === SeatStatuses.MySeat.value,
            );
            const hasExistingSeatBeenSelected =
                existingAssignedSeats.find((x) => x.seatId === selectedSeat.id) !== undefined;

            const existingReservations = existingAssignedSeats.flatMap((x) => x.timeslots);
            const existingConfirmedReservations = existingReservations.filter(
                (y) => y.seatStatus.reservationState === ReservationState.Confirmed,
            );

            if (existingReservations?.length > 0) {
                if (
                    hasExistingSeatBeenSelected &&
                    existingReservations.length === existingConfirmedReservations.length
                ) {
                    // Cancel seat reservation

                    const cancelInstructions = existingConfirmedReservations.map((y) => {
                        return {
                            timeslot: y,
                            facility: props.facility,
                        } as ICancelationInfo;
                    });

                    handleMakingReservation(
                        'cancel',
                        props.facility,
                        undefined,
                        undefined,
                        cancelInstructions,
                    );
                } else if (existingConfirmedReservations?.length > 0) {
                    const rescheduleInstructions = existingConfirmedReservations.map((y) => {
                        return {
                            currentTimeSlot: y,
                            changeToSeatId: selectedSeat.id,
                            facility: props.facility,
                        } as IRescheduleInfo;
                    });

                    const makeInstructions = selectedSeatSlot?.timeslots
                        .filter((x) => x.seatStatus.reservationState === undefined) // Make sure the seat is free
                        .filter(
                            (x) =>
                                rescheduleInstructions.findIndex(
                                    (y) =>
                                        y.currentTimeSlot.timeslotItem
                                            .startDateTimeUTCMilliseconds ===
                                        x.timeslotItem.startDateTimeUTCMilliseconds,
                                ) === -1,
                        ) // Make sure this is not a timeslot we're rescheduling
                        .map((x) => {
                            return {
                                timeslot: x.timeslotItem,
                                seatId: x.seatStatus.seatId,
                                facility: props.facility!,
                            } as IMakeReservationInfo;
                        });

                    handleMakingReservation(
                        'reschedule',
                        props.facility,
                        makeInstructions,
                        rescheduleInstructions,
                        undefined,
                    );
                    // if existingAssignedSeats are found assigned to the user, need to remove them and consolidate to new seat
                }
            } else if (selectedSeatSlot) {
                const makeInstructions = selectedSeatSlot?.timeslots.map((x) => {
                    return {
                        timeslot: x.timeslotItem,
                        seatId: x.seatStatus.seatId,
                        facility: props.facility!,
                    } as IMakeReservationInfo;
                });

                handleMakingReservation(
                    'schedule',
                    props.facility,
                    makeInstructions,
                    undefined,
                    undefined,
                );
            }
        }
    }, [selectedSeat, seatSlots]);

    const onSeatSelected = useCallback((seatSlot: ISeatSlot) => {
        const unitId = seatSlot?.unitId?.toLowerCase();
        if (!unitId) {
            return;
        }

        const seatDict = getFacilitySeatDict(eventObjRef.current.facilitySeats);
        const seat = seatDict[unitId];
        if (!seat) {
            return;
        }

        setSelectedSeat(seat);
    }, []);

    const [reservationInstruction, setReservationInstruction] = useState<
        IReservationInstructions | undefined
    >(undefined);

    function handleMakingReservation(
        type: 'cancel' | 'reschedule' | 'schedule',
        facility: IFacilityRecord,
        makeReservations?: IMakeReservationInfo[],
        rescheduleReservations?: IRescheduleInfo[],
        cancelReservations?: ICancelationInfo[],
    ): void {
        // require no dialog for the kiosk if the available seat is general/hierarchy provision type
        setReservationInstruction({
            facility: facility,
            make: makeReservations,
            reschedule: rescheduleReservations,
            cancel: cancelReservations,
        });
        const icmDescription = '';
        if (authContext.isKioskRenderMode()) {
            // Running in kiosk mode so just do the requested work, no confirm dialogs.
            const conclusion: IReservationPromiseInstruction[] = [];
            if (makeReservations) {
                conclusion.push(
                    ...createMakePromises(
                        authContext,
                        userContext,
                        makeReservations,
                        icmDescription,
                    ),
                );
            }
            if (rescheduleReservations) {
                conclusion.push(
                    ...createReschedulePromises(authContext, userContext, rescheduleReservations),
                );
            }
            if (cancelReservations) {
                conclusion.push(
                    ...createCancelPromises(authContext, userContext, cancelReservations),
                );
            }
            Promise.all(conclusion.map((x) => x.promise)).then(() => {
                onModalConcluded(ModalConclusion.Done, conclusion);
            });
        } else if (
            !authContext.isKioskRenderMode()
            //TODO make livesite dialog work || makeReservations.seatInfo.provisionInfo.provisionType === ProvisionType.LiveSite
        ) {
            switch (type) {
                case 'cancel':
                    setCancelReservationDialogOpen(true);
                    break;
                case 'reschedule':
                    setChangeReservationDialogOpen(true);
                    break;
                case 'schedule':
                    setReservationDialogOpen(true);
                    break;
            }
        }
    }

    const [reservationPromiseInstructions, setReservationPromiseInstructions] = useState<
        IReservationPromiseInstruction[]
    >();

    function onModalConcluded(
        modalConclusion: ModalConclusion,
        reservationResults: IReservationPromiseInstruction[],
    ): void {
        if (modalConclusion === ModalConclusion.Done) {
            setReservationPromiseInstructions(reservationResults);
        }
        setChangeReservationDialogOpen(false);
        setCancelReservationDialogOpen(false);
        setReservationDialogOpen(false);
        setSelectedSeat(undefined);
    }

    useEffect(() => {
        if (reservationPromiseInstructions && props.updateFacilitySeatStatuses) {
            props.updateFacilitySeatStatuses(reservationPromiseInstructions);
        }
    }, [reservationPromiseInstructions]);

    return (
        <>
            <FacilitiesSvgMap
                svg={props.facility?.svgMap ?? ''}
                seatSlots={seatSlots}
                onSeatSelected={onSeatSelected}
            />
            {isReservationDialogOpen && reservationInstruction && (
                <FacilitiesReservationModalV2
                    reservationInstructions={reservationInstruction}
                    onModalConcluded={onModalConcluded}
                    show={isReservationDialogOpen}
                    iseatRecords={eventObjRef.current.facilitySeats ?? []}
                />
            )}
            {isCancelReservationDialogOpen && reservationInstruction && (
                <CancelReservationModalActionButton
                    reservationInstructions={reservationInstruction}
                    iseatRecords={eventObjRef.current.facilitySeats ?? []}
                    onModalConcluded={onModalConcluded}
                    renderWithoutButton={true}
                />
            )}
            {isChangeReservationDialogOpen && reservationInstruction && (
                <ChangeReservationModal
                    reservationInstructions={reservationInstruction}
                    iseatRecords={eventObjRef.current.facilitySeats ?? []}
                    onModalConcluded={onModalConcluded}
                />
            )}
        </>
    );
}
