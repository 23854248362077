import FacilitiesClient, { IFacilityRecord, ISeatRecord } from 'clients/facilities-client';
import { FacilityUserType } from 'utils/facilities-utils';
import CheckAccess from 'components/common/check-access';
import { AuthContext } from 'contexts/auth-context';
import { ServiceProviderType, UserContext } from 'contexts/user-context';
import { Stack } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useFetchSimple, useIsMounted } from 'utils/misc-hooks';
import FacilitiesAzureIndoorMapDisplay from 'components/facilities/facilities-azure-indoor-map/facilities-azure-indoor-map-display';

export interface FacilitiesDisplayPageProps {
    match: {
        params: {
            facilityId?: string;
        };
    };
}

const fiveMinutesInMilliseconds = 300000;

export default function FacilitiesDisplayPage(props: FacilitiesDisplayPageProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [facility, setFacility] = useState<IFacilityRecord>();
    const [seats, setSeats] = useState<ISeatRecord[]>([]);
    const [hasFeaturesLoaded, setFeaturesLoaded] = useState<boolean>(false);
    const isMounted = useIsMounted();

    useFetchSimple<IFacilityRecord>({
        dependencies: [userContext.isFacilitiesTokenLoaded],
        canPerformFetch: userContext.isFacilitiesTokenLoaded,
        fetchFunc: async () => {
            const facilityId = props?.match?.params?.facilityId;
            if (facilityId) {
                const token = await FacilitiesClient.getFacilitiesToken(authContext);
                if (FacilitiesClient.canAccessFacility(token, props.match.params.facilityId)) {
                    return FacilitiesClient.getFacilityRecord(authContext, userContext, facilityId);
                }
            }
            throw 'error on getting facility';
        },
        onSuccess: (result) => {
            if (isMounted()) {
                setFacility(result);
            }
        },
        onError: (e) => {
            console.error(`Error with getting FacilityInfo`, e);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onFinally: () => {},
    });

    const getSeats = async (facilityId: string): Promise<void> => {
        try {
            const newSeats: ISeatRecord[] = [];

            let continuationToken = '';
            do {
                const seatsPagedResults = await FacilitiesClient.getSeatsByFacilityId(
                    authContext,
                    userContext,
                    facilityId,
                    continuationToken,
                );

                seatsPagedResults.results.forEach((x) => {
                    newSeats.push(x);
                });
                continuationToken = seatsPagedResults.continuationToken ?? '';
            } while (continuationToken);

            if (isMounted()) {
                setSeats(newSeats);
            }
        } catch (error) {
            console.log('Failed to retrieve seats for facility');
        }
    };

    useEffect(() => {
        if (facility) {
            getSeats(facility.id); // initial call

            const interval = setInterval(() => {
                // TODO: Fix facilities token not updating when expired (probably caused by the interval only looking at objects at it's moment of creation)
                getSeats(facility.id);
            }, fiveMinutesInMilliseconds); // periodic calls
            return () => clearInterval(interval);
        }
    }, [facility]);

    return (
        <>
            <CheckAccess
                requiredAccessTypeAny={[
                    {
                        serviceProvider: ServiceProviderType.Facility,
                        value: FacilityUserType.UserRole,
                    },
                ]}
                accessDeniedContent={<></>}>
                {facility && (
                    <Stack>
                        <Stack.Item
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'calc(100vh - 60px)',
                            }}>
                            <FacilitiesAzureIndoorMapDisplay
                                theme={'dark'}
                                facility={facility}
                                facilitySeats={hasFeaturesLoaded ? seats : undefined} // we only want to trigger seats changing when all map features have been loaded
                                onFeaturesLoaded={(): void => setFeaturesLoaded(true)}
                                featuresLoaded={hasFeaturesLoaded}
                            />
                        </Stack.Item>
                    </Stack>
                )}
            </CheckAccess>
        </>
    );
}
